export const idlFactory = ({ IDL }) => {
  const SpawnCanister = IDL.Record({
    'id' : IDL.Principal,
    'hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'version' : IDL.Nat64,
  });
  const ApiErrorType = IDL.Variant({
    'NotFound' : IDL.Null,
    'Unauthorized' : IDL.Null,
    'BadRequest' : IDL.Null,
  });
  const ApiError = IDL.Record({
    'err_type' : ApiErrorType,
    'err_msg' : IDL.Text,
  });
  const Result = IDL.Variant({ 'Ok' : SpawnCanister, 'Err' : ApiError });
  const Result_1 = IDL.Variant({ 'Ok' : IDL.Text, 'Err' : ApiError });
  const CanisterInstallMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Opt(IDL.Opt(IDL.Bool)),
    'install' : IDL.Null,
  });
  const Result_2 = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : ApiError });
  return IDL.Service({
    'get_controllers' : IDL.Func([IDL.Principal], [IDL.Vec(IDL.Principal)], []),
    'list_buckets' : IDL.Func([], [IDL.Vec(SpawnCanister)], ['query']),
    'spawn_bucket' : IDL.Func([], [Result], []),
    'test_cdn' : IDL.Func([], [Result_1], ['query']),
    'upgrade_canister' : IDL.Func(
        [IDL.Principal, IDL.Opt(CanisterInstallMode)],
        [Result_2],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };
