import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Backend, Query } from '../../services/api/backend';
import { Entity } from '../../components/crud/Schema';
import { serviceName, decodeData, entityUniqueIdentifier, deserialiseData } from '../../components/crud/Base';

export type GetMethod = { One: [string] } | { Many: [string][] };

export interface GetOneArgs {
  e: Entity;
  cid: string;
  method: GetMethod;
};

export const crudGetApi = createApi({
  reducerPath: 'crudGetApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getByIds: builder.query<any, GetOneArgs>({
      queryFn: async (args) => {
        try {
          const loadActor: any = await Backend.loadActorByName(serviceName(args.e) as keyof Backend.ActorTypes, args.cid);
          const queryObj: Query = {
            entity : entityUniqueIdentifier(args.e),
            method: args.method,
            offset: 0,
            limit: [],
            filter: [],
            order: [],
            format: {
              Rows: null
            }
          };
          const result = await loadActor[`crud_load`](queryObj);
          console.log(result);

          if (result.Err) {
            return { error: { status: 'CUSTOM_ERROR', error: JSON.stringify(result.Err) }};
          }
          const getResult = result.Ok.Rows;
          
          if (getResult && getResult[0]) {
            // console.log(getResult);
            const dataJson = deserialiseData(decodeData(getResult));
            console.log(dataJson);
            if (Object.keys(args.method)[0] === 'Many') {
              return { data: dataJson };
            }
            // console.log(dataJson[0]);
            return { data: dataJson[0] };
          }
          return { error: { status: 'CUSTOM_ERROR', error: `Ids does not exist!` } };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

export const { useGetByIdsQuery } = crudGetApi;
