import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Schema, parsedTypes, getSchemaVersion, getSchemaTimestamp } from '../components/crud/Schema';

export const RECORDS_PATH = 'entities';

export const loadState = (): Schema | {} => {
  try {
    const serializedState = localStorage.getItem('schema');
    const savedVersion  = localStorage.getItem('schema_version');
    if (serializedState !== null && savedVersion === getSchemaVersion()) {
      return JSON.parse(serializedState);
    } else {
      const schemaData = parsedTypes();
      // console.log(JSON.stringify(schemaData));
      const schemaVersion = getSchemaVersion();
      const schemaTimestamp = getSchemaTimestamp();
      // Load state from file here
      // localStorage.setItem("schema", JSON.stringify(schemaData));
      // localStorage.setItem("schema_version", schemaVersion);
      return schemaData;
    }
  } catch (err) {
    console.error("Error loading state:", err);
    return {};
  }
};

export const saveState = (state: Schema): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('schema', serializedState);
  } catch {
    // Ignore write errors.
  }
};

export const schemaSlice = createSlice({
  name: 'schema',
  initialState: {
    data: {}, // Initial state
  },
  reducers: {
    setSchema: (state, action: PayloadAction<Schema>) => {
      state.data = action.payload;
    },
  },
});

export const { setSchema } = schemaSlice.actions;
export const schemaData = (state: Schema) => state.schema;
export default schemaSlice.reducer;