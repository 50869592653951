import moment from 'moment';
import packageJson from '../../../../package.json';
import { schemaCreated, schemaVersion } from '../crud/Schema';

const Footer: React.FC = () => {
  // console.log(schemaCreated);
  return (
  <footer className="footer footer-sticky bg-light">
    <div className="container-fluid">
      <div className="p-3">
         <a target="_blank" href="https://dragginz.io/">
         © {new Date().getFullYear()}  Dragginz
          </a>
          <div className='px-2 float-end'>Schema Version: <strong>{schemaVersion}</strong></div>
          <div className='px-2 float-end'>Schema Date: <strong>{moment(Number(schemaCreated) * 1000).format("YYYY-MM-DD hh:mm:ss")}</strong></div>
          <div className='px-2 float-end'>Admin Version: <strong>{packageJson.version}</strong></div>
      </div>
       
    </div>
  </footer>
  
  
  );
};
export default Footer;

