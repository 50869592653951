import { memo, useEffect, useState } from "react";
import { Context, FormElementState, deleteByKey, filterAndUpdate, formState, setFormValue } from "../../reducers/formSlice";
import { Field } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { CONTEXT_UPDATE, emptyOrNull, fieldName, getStateNameExtra } from "../crud/Base";
import FormElement from "./form.element";
import { extractKeys } from "../crud/DataProcessor";

interface ManyElement {
  field: Field;
  width: number;
  extras: string;
  data: any[];
  
};

const ManyElement: React.FC<ManyElement> = (props: ManyElement): JSX.Element => {
  // const [nullValue, setNullValue] = useState(false);
  const [valueMap, setValueMap] = useState(new Map<number, Field>());
  const dispatch = useAppDispatch();
  const { field, width, extras, data } = props;
  const { context } = useAppSelector(formState);

  useEffect(() => {
    if (context === CONTEXT_UPDATE && data.length > 0) {
      const keys = extractKeys(field, data, extras);
      const newMap = new Map<number, Field>();
      keys.forEach((key, index) => {
        newMap.set(key, field); 
      });
      setValueMap(newMap);
    } else {
      const initialObj: FormElementState = {
        key: getStateNameExtra(field, extras),
        value: [],
        extras: getStateNameExtra(field, extras),
        error: [],
      };
      dispatch(setFormValue(initialObj));
    }
  }, []);

  const add = (e: React.MouseEvent): void => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    const newKey = valueMap.size ? Math.max(...Array.from(valueMap.keys())) + 1 : 0; // compute the new key
    newMap.set(newKey, field);
    console.log(newMap);
    dispatch(deleteByKey(getStateNameExtra(field, extras)));
    setValueMap(newMap);
  };

  const remove = (e: React.MouseEvent, key: number, f: any) => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    newMap.delete(key);
    setValueMap(newMap);
    dispatch(filterAndUpdate(f));
    if (Array.from(newMap.values()).filter((e: any) => !emptyOrNull(e)).length === 0) {
      dispatch(setFormValue({
        key: getStateNameExtra(field, extras),
        value: [],
        extras: getStateNameExtra(field, extras),
        error: [],
      }));
    }
  };
 
  return (
  <div className='row border border-info  mb-1 mt-1 rounded g-1'> 
    {Array.from(valueMap.entries()).map(([key, field]) => {
        let copyObj: Field = { ...field };
        copyObj['value'] = { ...field.value, cardinality: 'One' };
        const fieldExtra = `${fieldName(field)}-array_key_${key}-`;
        return (
          <div key={key} className='row container border rounded mb-1 mt-1 g-1'>
            <FormElement
              field={copyObj} 
              width={width}
              extras={extras + fieldExtra}
              data={data}
            />
            {valueMap.size > 0 &&
              <button onClick={(e: any) => remove(e, key, extras + fieldExtra)} className="btn btn-outline-danger close-button">x</button>
            }
          </div>
        );
    })}
    <div><button type="button" className='btn btn-primary btn-sm mb-1' onClick={(e: React.MouseEvent) => add(e)}>Many: {fieldName(field)} </button> </div>
  </div>
  )
  
};

export default memo(ManyElement);