import { useEffect, useState, memo } from "react";
import { EnumValue, EnumValueVariant, Field, GenericNestedObject } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { FormElementState, formState, setFormValue } from "../../reducers/formSlice";
import { CONTEXT_UPDATE, emptyOrNull, fieldCardinalityOpt, fieldName, getStateNameExtra } from "../crud/Base";
import Select from 'react-select';
import { Nullable } from "./nullable";


interface EnumValueElement {
  field: Field;
  parentRef: EnumValue;
  width: number;
  extras: string;
  data: any;
};

const guidesToDropdown = (guides: EnumValueVariant[]) => {
  const options = [];
  for (const guide of guides) {
    if (guide.unspecified) {
      continue;
    }
    const value = guide.value;
    options.push({ 
      value: value,
      name: guide.name,
      label: `${guide.name} (${value})`,
      default: guide.default === true
     })
  }
  return options;
}

const findGuide = (name: any, guides: any[]) => {
  const matchedGuide = guides.find(guide => {
    return guide.name === name;
  });

  return matchedGuide;
}

const EnumValueElement: React.FC<EnumValueElement> = (props) => {
  const [value, setValue] = useState({});
  const [nullValue, setNullValue] = useState(true);
  const { field, width, extras, parentRef, data } = props;
  const dispatch = useAppDispatch();
  const { form, context } = useAppSelector(formState);

  // console.log(parentRef.variants);
  const entries = guidesToDropdown(parentRef.variants);
  const preDefaultVal = entries.find((e: any) => e.default === true);
  const defaultDropdown = preDefaultVal === undefined ? entries[0] : preDefaultVal;

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find((f: FormElementState) => f.key === getStateNameExtra(field, extras) );
    if (stateValue) {
      const newValue = checked ? defaultDropdown : null;
      dispatch(setFormValue({ ...stateValue, value: newValue !== null ? newValue.value : null  }));
      if (newValue !== null) {
        setValue(newValue);
      }
    }
  };

  useEffect(() => {
    const nullable = fieldCardinalityOpt(field);
    if (nullable) {
      setNullValue(false);
    }
    dispatch(setFormValue({
      key: getStateNameExtra(field, extras),
      value: nullable ? null : defaultDropdown.name,
      extras: getStateNameExtra(field, extras),
      error: []
    }));
    if (Object.values(value).length === 0) {
      setValue(defaultDropdown);
    }
    console.log(data);
    if (context === CONTEXT_UPDATE && data !== undefined) {
      const updateValue = data.value;
      // console.log(updateValue);
      const selectedGuide = findGuide(updateValue, entries);
      if (!emptyOrNull(updateValue)) {
        setNullValue(true);
        setValue(selectedGuide);
        dispatch(setFormValue({
          key: getStateNameExtra(field, extras),
          value: updateValue,
          error: [],
          extras: getStateNameExtra(field, extras),
        }));
      }
      if (updateValue === null ){ 
        setNullValue(false);
      }
        // console.log(entries);
        // console.log(updateValue);
        // console.log(selectedGuide);
    }
  }, []);

  const handleChange = (selectedOption: any) => {
    console.log(selectedOption);
    const value = selectedOption === null ? null : selectedOption.name;
    dispatch(setFormValue({
      key: getStateNameExtra(field, extras),
      value: value,
      extras: getStateNameExtra(field, extras),
      error: []
    }));
    setValue(selectedOption);
  };
  return (
    <div className="row g-1">
    <div className="col-md-1">
      <label className="text-break">
        <small><strong>{fieldName(field)}</strong></small>
      </label>
    </div>
    {fieldCardinalityOpt(field) && (
      <div className="col-md-1 d-flex justify-content-center">
        <div className="form-check form-switch">
          <Nullable field={field} checked={nullValue} onChange={nullableClick} />
        </div>
      </div>
    )}
    {nullValue === true && (
      <div className={`col-md-${width}`}>
        <div className="form-group">
          <Select
            value={value}
            options={entries}
            onChange={handleChange}
            isClearable={true}
          />
        </div>
      </div>
    )}
  </div>
  )
};


export default memo(EnumValueElement);