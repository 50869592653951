export const idlFactory = ({ IDL }) => {
  const AppMode = IDL.Variant({
    'Enabled' : IDL.Null,
    'Disabled' : IDL.Null,
    'Readonly' : IDL.Null,
  });
  const AppState = IDL.Record({ 'mode' : AppMode });
  const Result = IDL.Variant({
    'Ok' : IDL.Null,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const AssetType = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'ident' : IDL.Text,
  });
  const Entry = IDL.Record({ 'path' : IDL.Text, 'asset_type_id' : IDL.Text });
  const Asset = IDL.Record({
    'entity_ident' : IDL.Opt(IDL.Text),
    'entries' : IDL.Vec(Entry),
    'entity_path' : IDL.Text,
    'entity_id' : IDL.Text,
  });
  const AssetList = IDL.Record({
    'asset_type' : IDL.Vec(AssetType),
    'asset' : IDL.Vec(Asset),
  });
  const Result_1 = IDL.Variant({
    'Ok' : AssetList,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const FromUserRecord = IDL.Record({ 'user_id' : IDL.Principal });
  const FromCanisterRecord = IDL.Record({
    'canister_version' : IDL.Opt(IDL.Nat64),
    'canister_id' : IDL.Principal,
  });
  const CanisterChangeOrigin = IDL.Variant({
    'from_user' : FromUserRecord,
    'from_canister' : FromCanisterRecord,
  });
  const CreationRecord = IDL.Record({ 'controllers' : IDL.Vec(IDL.Principal) });
  const CodeDeploymentMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Null,
    'install' : IDL.Null,
  });
  const CodeDeploymentRecord = IDL.Record({
    'mode' : CodeDeploymentMode,
    'module_hash' : IDL.Vec(IDL.Nat8),
  });
  const LoadSnapshotRecord = IDL.Record({
    'canister_version' : IDL.Nat64,
    'taken_at_timestamp' : IDL.Nat64,
    'snapshot_id' : IDL.Vec(IDL.Nat8),
  });
  const CanisterChangeDetails = IDL.Variant({
    'creation' : CreationRecord,
    'code_deployment' : CodeDeploymentRecord,
    'load_snapshot' : LoadSnapshotRecord,
    'controllers_change' : CreationRecord,
    'code_uninstall' : IDL.Null,
  });
  const CanisterChange = IDL.Record({
    'timestamp_nanos' : IDL.Nat64,
    'canister_version' : IDL.Nat64,
    'origin' : CanisterChangeOrigin,
    'details' : CanisterChangeDetails,
  });
  const CanisterInfoResponse = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'recent_changes' : IDL.Vec(CanisterChange),
    'total_num_changes' : IDL.Nat64,
  });
  const Result_2 = IDL.Variant({
    'Ok' : IDL.Text,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const CanisterState = IDL.Record({
    'root_id' : IDL.Opt(IDL.Principal),
    'path' : IDL.Opt(IDL.Text),
    'parent_id' : IDL.Opt(IDL.Principal),
  });
  const Model = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'part_ids' : IDL.Vec(IDL.Text),
    'slot_layout_id' : IDL.Text,
    'asset_model_id' : IDL.Text,
  });
  const Slot = IDL.Variant({
    'Ears' : IDL.Null,
    'Eyes' : IDL.Null,
    'Face' : IDL.Null,
    'Feet' : IDL.Null,
    'Hair' : IDL.Null,
    'Hand' : IDL.Null,
    'Head' : IDL.Null,
    'Legs' : IDL.Null,
    'Nose' : IDL.Null,
    'Tail' : IDL.Null,
    'Waist' : IDL.Null,
    'Wrist' : IDL.Null,
    'Mouth' : IDL.Null,
    'Finger' : IDL.Null,
    'Chest' : IDL.Null,
    'Gloves' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Entry_1 = IDL.Record({
    'order' : IDL.Nat8,
    'slot' : Slot,
    'item_id' : IDL.Text,
  });
  const Equipment = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'entries' : IDL.Vec(Entry_1),
  });
  const Section = IDL.Variant({
    'Chin' : IDL.Null,
    'Eyebrows' : IDL.Null,
    'Ears' : IDL.Null,
    'Eyes' : IDL.Null,
    'Face' : IDL.Null,
    'Hair' : IDL.Null,
    'Underwear' : IDL.Null,
    'Lips' : IDL.Null,
    'Nose' : IDL.Null,
    'Skin' : IDL.Null,
    'Eyelashes' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Part = IDL.Record({
    'id' : IDL.Text,
    'section' : Section,
    'ident' : IDL.Text,
    'customizer_ids' : IDL.Vec(IDL.Text),
    'mesh_ids' : IDL.Vec(IDL.Text),
  });
  const StackVariantOne = IDL.Record({ 'shape_id' : IDL.Opt(IDL.Text) });
  const Stack = IDL.Variant({
    'Six' : IDL.Null,
    'Ten' : IDL.Null,
    'Two' : IDL.Null,
    'Eight' : IDL.Null,
    'TwoHundred' : IDL.Null,
    'Five' : IDL.Null,
    'Four' : IDL.Null,
    'Twelve' : IDL.Null,
    'Twenty' : IDL.Null,
    'Fifty' : IDL.Null,
    'Thirteen' : IDL.Null,
    'OneHundred' : IDL.Null,
    'Three' : IDL.Null,
    'Unspecified' : IDL.Null,
    'FiveHundred' : IDL.Null,
  });
  const StackVariantMany = IDL.Record({ 'stack' : Stack });
  const StackVariant = IDL.Variant({
    'One' : StackVariantOne,
    'Many' : StackVariantMany,
  });
  const Nft = IDL.Record({ 'limit' : IDL.Nat32, 'allow_reissue' : IDL.Bool });
  const Nft_1 = IDL.Record({ 'nft_attribute' : Nft });
  const Span = IDL.Variant({
    'One' : IDL.Null,
    'Two' : IDL.Null,
    'Four' : IDL.Null,
  });
  const Dominance = IDL.Variant({
    'Secondary' : IDL.Null,
    'Primary' : IDL.Null,
  });
  const CarryMode = IDL.Variant({
    'TwoHandRanged' : IDL.Null,
    'TwoHandMelee' : IDL.Null,
    'OneHandRanged' : IDL.Null,
    'OneHandShield' : IDL.Null,
    'OneHandMelee' : IDL.Null,
  });
  const EquipTarget = IDL.Record({
    'slot' : Slot,
    'span' : Span,
    'dominance' : IDL.Opt(Dominance),
    'carry_mode' : IDL.Opt(CarryMode),
  });
  const Insulation = IDL.Variant({
    'Light' : IDL.Null,
    'High' : IDL.Null,
    'None' : IDL.Null,
    'Normal' : IDL.Null,
  });
  const ModifierGroup = IDL.Record({
    'movement' : IDL.Opt(IDL.Int8),
    'carry' : IDL.Opt(IDL.Int8),
    'armour' : IDL.Opt(IDL.Int8),
    'warmth' : IDL.Opt(IDL.Int8),
    'health' : IDL.Opt(IDL.Int8),
  });
  const Equipment_1 = IDL.Record({
    'equip_target' : EquipTarget,
    'is_boe' : IDL.Bool,
    'insulation' : Insulation,
    'stat_modifiers' : IDL.Opt(ModifierGroup),
  });
  const Satiation = IDL.Variant({
    'Snack' : IDL.Null,
    'Bite' : IDL.Null,
    'Meal' : IDL.Null,
    'Feast' : IDL.Null,
    'Serving' : IDL.Null,
    'Unspecified' : IDL.Null,
    'Morsel' : IDL.Null,
  });
  const Potency = IDL.Variant({
    'Low' : IDL.Null,
    'VeryHigh' : IDL.Null,
    'High' : IDL.Null,
    'VeryLow' : IDL.Null,
    'Normal' : IDL.Null,
  });
  const Food = IDL.Record({
    'satiation' : Satiation,
    'poison' : IDL.Opt(Potency),
  });
  const Prop = IDL.Record({ 'prop_id' : IDL.Text });
  const Spell = IDL.Record({ 'spell_id' : IDL.Text });
  const Drink = IDL.Record({
    'booze' : IDL.Opt(Potency),
    'poison' : IDL.Opt(Potency),
  });
  const Hatchable = IDL.Record({ 'pet_stage_id' : IDL.Text });
  const Recipe = IDL.Record({ 'recipe_id' : IDL.Text });
  const Set = IDL.Record({
    'nft' : IDL.Opt(Nft_1),
    'equipment' : IDL.Opt(Equipment_1),
    'food' : IDL.Opt(Food),
    'prop' : IDL.Opt(Prop),
    'seed' : IDL.Opt(IDL.Record({})),
    'spell' : IDL.Opt(Spell),
    'drink' : IDL.Opt(Drink),
    'hatchable' : IDL.Opt(Hatchable),
    'recipe' : IDL.Opt(Recipe),
  });
  const Item = IDL.Record({
    'id' : IDL.Text,
    'is_live' : IDL.Bool,
    'can_trade' : IDL.Bool,
    'rarity_id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Opt(IDL.Text),
    'stack_variant' : StackVariant,
    'attributes' : Set,
    'substance_id' : IDL.Opt(IDL.Text),
    'can_sell' : IDL.Bool,
    'category_id' : IDL.Text,
  });
  const ParameterValue = IDL.Variant({
    'Number' : IDL.Float64,
    'Color' : IDL.Text,
  });
  const Parameter = IDL.Record({
    'value' : ParameterValue,
    'key_id' : IDL.Text,
  });
  const Modifier = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'parameters' : IDL.Vec(Parameter),
  });
  const Customizer = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'part_modifier_ids' : IDL.Vec(IDL.Text),
  });
  const Set_1 = IDL.Record({ 'nft' : IDL.Opt(Nft_1) });
  const Type = IDL.Variant({
    'Secondary' : IDL.Null,
    'Primary' : IDL.Null,
    'Keeper' : IDL.Null,
    'Backstory' : IDL.Null,
    'Special' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Skill = IDL.Variant({
    'Gardening' : IDL.Null,
    'Survival' : IDL.Null,
    'Cooking' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Skill_1 = IDL.Record({ 'rank' : IDL.Nat8, 'skill' : Skill });
  const Ability = IDL.Record({ 'ability_id' : IDL.Text });
  const Set_2 = IDL.Record({
    'pet_limit' : IDL.Opt(IDL.Nat8),
    'flow' : IDL.Opt(IDL.Int32),
    'spell_limit' : IDL.Opt(IDL.Nat8),
    'skill' : IDL.Opt(Skill_1),
    'ability' : IDL.Opt(Ability),
  });
  const Role = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'rank' : IDL.Nat8,
    'description' : IDL.Text,
    'name_feminine' : IDL.Opt(IDL.Text),
    'attributes' : Set_1,
    'role_type' : Type,
    'modifiers' : Set_2,
    'category_id' : IDL.Text,
  });
  const Gender = IDL.Variant({ 'Male' : IDL.Null, 'Female' : IDL.Null });
  const Choice = IDL.Record({
    'id' : IDL.Text,
    'currency_template_id' : IDL.Opt(IDL.Text),
    'backstory_id' : IDL.Text,
    'stat_modifiers' : ModifierGroup,
    'gender' : Gender,
    'equipment_template_id' : IDL.Opt(IDL.Text),
    'species_id' : IDL.Text,
    'model_id' : IDL.Text,
    'backpack_template_id' : IDL.Opt(IDL.Text),
  });
  const Model_1 = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
  });
  const ParameterKey = IDL.Record({ 'id' : IDL.Text, 'ident' : IDL.Text });
  const Mesh = IDL.Record({ 'id' : IDL.Text, 'name' : IDL.Text });
  const Rarity = IDL.Record({
    'id' : IDL.Text,
    'key' : IDL.Nat64,
    'order' : IDL.Nat8,
    'name' : IDL.Text,
    'color' : IDL.Text,
    'description' : IDL.Text,
  });
  const Source = IDL.Variant({
    'One' : IDL.Text,
    'Set' : IDL.Text,
    'Category' : IDL.Text,
  });
  const Backstory = IDL.Record({
    'id' : IDL.Text,
    'role_ids' : IDL.Vec(IDL.Text),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'abilities' : IDL.Opt(Source),
    'starting_zone_id' : IDL.Text,
  });
  const Species = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'tree_id' : IDL.Text,
  });
  const CharacterCreator = IDL.Record({
    'model' : IDL.Vec(Model),
    'item_equipment_template' : IDL.Vec(Equipment),
    'model_part' : IDL.Vec(Part),
    'item' : IDL.Vec(Item),
    'model_part_modifier' : IDL.Vec(Modifier),
    'model_part_customizer' : IDL.Vec(Customizer),
    'role' : IDL.Vec(Role),
    'creator_choice' : IDL.Vec(Choice),
    'asset_model' : IDL.Vec(Model_1),
    'skill' : IDL.Vec(Skill),
    'model_part_parameter_key' : IDL.Vec(ParameterKey),
    'model_part_mesh' : IDL.Vec(Mesh),
    'gender' : IDL.Vec(Gender),
    'rarity' : IDL.Vec(Rarity),
    'backstory' : IDL.Vec(Backstory),
    'species' : IDL.Vec(Species),
    'model_section' : IDL.Vec(Section),
  });
  const Result_3 = IDL.Variant({
    'Ok' : CharacterCreator,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Rotation = IDL.Record({
    'x' : IDL.Nat16,
    'y' : IDL.Nat16,
    'z' : IDL.Nat16,
  });
  const Side = IDL.Variant({
    'Top' : IDL.Null,
    'Back' : IDL.Null,
    'Left' : IDL.Null,
    'Right' : IDL.Null,
    'Bottom' : IDL.Null,
    'Forward' : IDL.Null,
  });
  const Scale = IDL.Record({
    'x' : IDL.Float64,
    'y' : IDL.Float64,
    'z' : IDL.Float64,
  });
  const Movement = IDL.Variant({ 'Tether' : IDL.Record({}) });
  const Attribute = IDL.Variant({ 'Movement' : Movement });
  const Object = IDL.Record({
    'rotation' : Rotation,
    'side' : Side,
    'scale' : IDL.Opt(Scale),
    'attributes' : IDL.Vec(Attribute),
    'position' : Scale,
    'prop_id' : IDL.Text,
    'ref_id' : IDL.Text,
  });
  const Data = IDL.Record({
    'data' : IDL.Vec(IDL.Nat8),
    'client_version' : IDL.Text,
    'edit_version' : IDL.Nat16,
    'objects' : IDL.Vec(Object),
    'material_ids' : IDL.Vec(IDL.Text),
  });
  const Config = IDL.Record({
    'x' : IDL.Int32,
    'y' : IDL.Int32,
    'z' : IDL.Int32,
    'data' : Data,
  });
  const Result_4 = IDL.Variant({
    'Ok' : IDL.Vec(Config),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const DeleteRequest = IDL.Record({
    'key' : IDL.Vec(IDL.Text),
    'entity' : IDL.Text,
  });
  const DeleteResponse = IDL.Record({
    'keys' : IDL.Vec(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text)))),
  });
  const Result_5 = IDL.Variant({
    'Ok' : DeleteResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const LoadMethod = IDL.Variant({
    'All' : IDL.Null,
    'One' : IDL.Vec(IDL.Text),
    'Many' : IDL.Vec(IDL.Vec(IDL.Text)),
    'Range' : IDL.Tuple(IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)),
    'Prefix' : IDL.Vec(IDL.Text),
  });
  const SortDirection = IDL.Variant({ 'Asc' : IDL.Null, 'Desc' : IDL.Null });
  const Filter = IDL.Variant({
    'All' : IDL.Text,
    'Fields' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const LoadFormat = IDL.Variant({ 'Rows' : IDL.Null, 'Count' : IDL.Null });
  const LoadRequest = IDL.Record({
    'entity' : IDL.Text,
    'method' : LoadMethod,
    'order' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, SortDirection))),
    'offset' : IDL.Nat32,
    'limit' : IDL.Opt(IDL.Nat32),
    'filter' : IDL.Opt(Filter),
    'format' : LoadFormat,
  });
  const Metadata = IDL.Record({
    'created' : IDL.Nat64,
    'modified' : IDL.Nat64,
  });
  const QueryValue = IDL.Record({
    'metadata' : Metadata,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const QueryRow = IDL.Record({
    'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    'value' : QueryValue,
  });
  const LoadResponse = IDL.Variant({
    'Rows' : IDL.Vec(QueryRow),
    'Count' : IDL.Nat32,
  });
  const Result_6 = IDL.Variant({
    'Ok' : LoadResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const SaveRequestAction = IDL.Variant({
    'Create' : IDL.Null,
    'Update' : IDL.Null,
  });
  const SaveRequest = IDL.Record({
    'entity' : IDL.Text,
    'action' : SaveRequestAction,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const CreateResponse = IDL.Record({ 'row' : QueryRow });
  const SaveResponse = IDL.Variant({
    'Create' : CreateResponse,
    'Update' : CreateResponse,
  });
  const Result_7 = IDL.Variant({
    'Ok' : SaveResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Derive = IDL.Record({ 'price' : IDL.Nat64 });
  const Result_8 = IDL.Variant({
    'Ok' : IDL.Vec(IDL.Tuple(Item, Derive)),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_9 = IDL.Variant({
    'Ok' : IDL.Vec(Rarity),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_10 = IDL.Variant({
    'Ok' : IDL.Vec(IDL.Text),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Placement = IDL.Record({
    'is_wall' : IDL.Bool,
    'is_ceiling' : IDL.Bool,
    'is_floor' : IDL.Bool,
  });
  const Cooldown = IDL.Variant({
    'Fast' : IDL.Null,
    'Slow' : IDL.Null,
    'Unspecified' : IDL.Null,
    'SuperFast' : IDL.Null,
    'SuperSlow' : IDL.Null,
  });
  const Difficulty = IDL.Variant({
    'VeryEasy' : IDL.Null,
    'VeryHard' : IDL.Null,
    'Easy' : IDL.Null,
    'Hard' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Modifier_1 = IDL.Variant({
    'CraftTime' : Cooldown,
    'Difficulty' : Difficulty,
    'Cooldown' : Cooldown,
    'Unspecified' : IDL.Null,
  });
  const SkillEntry = IDL.Record({ 'tier' : IDL.Nat8, 'skill' : Skill });
  const Station = IDL.Record({
    'modifiers' : IDL.Vec(Modifier_1),
    'skills' : IDL.Vec(SkillEntry),
  });
  const Nest = IDL.Record({ 'insulation' : Insulation });
  const Container = IDL.Record({ 'shape_id' : IDL.Text });
  const Weight = IDL.Variant({
    'Light' : IDL.Null,
    'Medium' : IDL.Null,
    'Extreme' : IDL.Null,
    'Heavy' : IDL.Null,
  });
  const Carry = IDL.Record({ 'weight' : Weight });
  const RegenSpeed = IDL.Variant({
    'VeryFast' : IDL.Null,
    'VerySlow' : IDL.Null,
    'Fast' : IDL.Null,
    'Average' : IDL.Null,
    'Slow' : IDL.Null,
  });
  const DiceU16 = IDL.Record({
    'multiplier' : IDL.Nat8,
    'modifier' : IDL.Nat8,
    'drop_lowest' : IDL.Nat8,
    'count' : IDL.Nat8,
    'drop_highest' : IDL.Nat8,
    'faces' : IDL.Nat8,
  });
  const InitialHarvest = IDL.Variant({
    'Full' : IDL.Null,
    'Lots' : IDL.Null,
    'None' : IDL.Null,
    'Some' : IDL.Null,
  });
  const Resource = IDL.Record({
    'regen_speed' : IDL.Opt(RegenSpeed),
    'destroy_on_empty' : IDL.Bool,
    'item_quantity' : DiceU16,
    'max_harvest' : IDL.Nat8,
    'initial_harvest' : InitialHarvest,
    'item_id' : IDL.Text,
  });
  const Attribute_1 = IDL.Variant({
    'Station' : Station,
    'Creature' : IDL.Record({}),
    'Lair' : IDL.Record({}),
    'Nest' : Nest,
    'Planter' : IDL.Record({}),
    'Container' : Container,
    'Dialogue' : IDL.Record({}),
    'Converter' : Container,
    'Carry' : Carry,
    'Movement' : IDL.Record({}),
    'Resource' : Resource,
  });
  const Prop_1 = IDL.Record({
    'id' : IDL.Text,
    'placement' : Placement,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'attributes' : IDL.Vec(Attribute_1),
    'substance_id' : IDL.Opt(IDL.Text),
    'model_id' : IDL.Opt(IDL.Text),
    'category_id' : IDL.Text,
  });
  const Block = IDL.Record({
    'x' : IDL.Nat32,
    'y' : IDL.Nat32,
    'z' : IDL.Nat32,
    'id' : IDL.Text,
    'is_live' : IDL.Bool,
    'name' : IDL.Text,
    'description' : IDL.Opt(IDL.Text),
  });
  const Material = IDL.Record({
    'id' : IDL.Text,
    'is_live' : IDL.Bool,
    'name' : IDL.Text,
  });
  const WorldBuilder = IDL.Record({
    'prop' : IDL.Vec(Prop_1),
    'block' : IDL.Vec(Block),
    'material' : IDL.Vec(Material),
  });
  const Result_11 = IDL.Variant({
    'Ok' : WorldBuilder,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  return IDL.Service({
    'app_state' : IDL.Func([], [AppState], ['query']),
    'app_state_cascade' : IDL.Func([AppState], [Result], []),
    'assets' : IDL.Func([], [Result_1], ['query']),
    'canister_caller' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_id' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_info' : IDL.Func([], [CanisterInfoResponse], []),
    'canister_path' : IDL.Func([], [Result_2], ['query']),
    'canister_state' : IDL.Func([], [CanisterState], ['query']),
    'canister_time' : IDL.Func([], [IDL.Nat64], ['query']),
    'canister_upgrade_children' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
    'canister_version' : IDL.Func([], [IDL.Nat64], ['query']),
    'character_creator_manifest' : IDL.Func([], [Result_3], ['query']),
    'child_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Text))],
        ['query'],
      ),
    'chunks' : IDL.Func(
        [IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)],
        [Result_4],
        ['query'],
      ),
    'crud_delete' : IDL.Func([DeleteRequest], [Result_5], []),
    'crud_load' : IDL.Func([LoadRequest], [Result_6], ['composite_query']),
    'crud_save' : IDL.Func([SaveRequest], [Result_7], []),
    'fixtures_replace_all' : IDL.Func([], [Result], []),
    'ic_cycles_accept' : IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    'init_async' : IDL.Func([], [Result], []),
    'items' : IDL.Func([], [Result_8], ['query']),
    'rarities' : IDL.Func([], [Result_9], ['query']),
    'rarities_query' : IDL.Func(
        [
          IDL.Text,
          IDL.Vec(IDL.Tuple(IDL.Text, SortDirection)),
          IDL.Nat32,
          IDL.Opt(IDL.Nat32),
        ],
        [Result_9],
        ['query'],
      ),
    'store_keys' : IDL.Func([IDL.Text], [Result_10], ['composite_query']),
    'subnet_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'subnet_index_cascade' : IDL.Func(
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        [Result],
        [],
      ),
    'world_builder_manifest' : IDL.Func([], [Result_11], ['query']),
  });
};
export const init = ({ IDL }) => { return [IDL.Principal, IDL.Principal]; };
