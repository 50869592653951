import { memo, useEffect, useState } from "react";
import { FormElementState, deleteByKey, filterAndUpdate, formState, setFormValue } from "../../reducers/formSlice";
import { Field, RecordType } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { CONTEXT_UPDATE, fieldCardinalityOpt, fieldName, getStateNameExtra } from "../crud/Base";
import FormElement from "./form.element";
import { Nullable } from "./nullable";

interface RecordElement {
  field: Field;
  parentRef: RecordType;
  width: number;
  extras: string;
  data: any;
};

const RecordElement: React.FC<RecordElement> = (props: RecordElement) => {
  const [embed, setEmbed] = useState<any[]>([]);
  const [nullValue, setNullValue] = useState(true); // by default show it
  const { field, parentRef, extras, data } = props;
  const dispatch = useAppDispatch();
  if (!parentRef.fields || Object.values(parentRef.fields).length === 0 ) {
    return <div className="text-danger">Fields missing in schema for record {fieldName(field)}, WIP</div>;
  }
  // console.log(parentRef);
  const embedV = Object.values(parentRef.fields.fields);
  
  const { form, context } = useAppSelector(formState);
  const initialObj: FormElementState = {
    key: getStateNameExtra(field, extras),
    value: null,
    extras: getStateNameExtra(field, extras),
    error: [],
  };
  useEffect(() => {
    const nullable = fieldCardinalityOpt(field);
    setEmbed(embedV);
    if (nullable) {
      setNullValue(false);
      dispatch(setFormValue(initialObj));
    }
    if (context === CONTEXT_UPDATE && data.length > 0) {
      // console.log(data);
      if (!(data.length === 1 && data[0].value === null)) {
        setNullValue(true);
      }
    }
  }, []);

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation(); 
    const target = e.target as HTMLInputElement;
    // console.log(target.checked);
    if (target.checked === true) {
      dispatch(deleteByKey(getStateNameExtra(field, extras)));
    } else {
      dispatch(filterAndUpdate(getStateNameExtra(field, extras)));
      dispatch(setFormValue(initialObj));
    }
    setNullValue(target.checked);
  };
  // console.log(disabled);
  // console.log(embed);
  return  <div className="row g-1">
  <div className="col-md-1">
    <label className="text-break">
      <small><strong>{fieldName(field)}</strong></small>
    </label>
  </div>
  {fieldCardinalityOpt(field) && (
    <div className="col-md-1 d-flex justify-content-center">
      <Nullable field={field} checked={nullValue} onChange={nullableClick} />
    </div>
  )}
  {nullValue === true && (
    <div className="col-md-10">
      <div className="row">
        <div className="form-group">
          <div className="col-md-12 container border rounded mb-1 mt-1">
            {embed.map((childField, i) => (
              <FormElement 
                key={childField.name}
                field={childField}
                data={data}
                extras={`${getStateNameExtra(field, extras)}-object-`}
                width={8}
              />
            ))}
          </div>
          <div className="invalid-feedback">
            Field Required
          </div>
        </div>
      </div>
    </div>
  )}
</div>
};


export default memo(RecordElement);