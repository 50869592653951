import { useContext, useEffect, useState } from "react";
import { RECORDS_PATH, loadState } from "../../reducers/schemaSlice";
import {
  entityName,
  entityUniqueIdentifier,
  getVisibleEntities,
} from "../crud/Base";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../../..";
import { useAppDispatch } from "../../services/hooks";
import { crudListApi } from "../../reducers/crud/listSlice";

interface MenuItem {
  id: string;
  title: string;
  icon: string;
  group: boolean;
  children: MenuItem[];
}

const groupChildren = (
  groups: MenuItem[],
  nav: string[],
  item: MenuItem
): MenuItem[] => {
  if (nav.length === 0) {
    return groups;
  }

  let groupName = nav[0];
  let group = groups.find((g) => g.title === groupName);

  if (nav.length > 1) {
    if (!group) {
      group = {
        id: groupName,
        title: groupName,
        children: [],
        group: true,
        icon: "bi-bug",
      };
      groups.push(group);
      groups.sort((a, b) => a.title.localeCompare(b.title));
    }

    group.children = groupChildren(group.children, nav.slice(1), item);
  } else {
    groups.push(item);
  }

  return groups;
};

export const generateNavigation = (): MenuItem[] => {
  const groups: MenuItem[] = [];
  const schema = loadState(); // Replace with actual schema loading logic
  // console.log(getVisibleEntities(schema));
  for (const entity of getVisibleEntities(schema)) {
    const uniqueId = entityUniqueIdentifier(entity);
    // console.log(uniqueId);
    const nav = uniqueId.split("::");
    const item: MenuItem = {
      id: uniqueId,
      icon: "bi-bug",
      group: false,
      title: entityName(entity),
      children: [],
    };

    groupChildren(groups, nav, item);
  }

  return groups;
};

const EnvironmentSelector = () => {
  const { environment, setEnvironment } = useContext(EnvironmentContext);
  const availableEnvironments = new Set(
    Object.keys(process.env.TEST_CANISTER_ID as any)
  );
  // console.log(Object.keys(process.env.TEST_CANISTER_ID as any));
  // console.log(availableEnvironments);
  const handleChange = (e: any) => {
    setEnvironment(e.target.value);
  };

  return (
    <div>
      <div
        className="alert alert-danger d-flex align-items-center"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        <div>
          current env: <strong>{environment.toUpperCase()}</strong>
        </div>
      </div>
      {availableEnvironments.size > 1 && (
        <select
          className="form-select"
          value={environment}
          onChange={handleChange}
        >
          {Array.from(availableEnvironments).map((env) => (
            <option key={env} value={env}>
              {env.charAt(0).toUpperCase() + env.slice(1)}{" "}
              {/* capitalize first letter */}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

const CollapsibleMenu: React.FC = () => {
  const [activeLink, setActiveLink] = useState<string>("");
  const [openSubmenu, setOpenSubmenu] = useState<Set<string>>(new Set());
  const [navigation, setNavigation] = useState<Array<MenuItem>>([]);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const decodedPath = decodeURIComponent(currentPath);
  const namePart = !decodedPath.split(`${RECORDS_PATH}/`)[1]
    ? decodedPath.split(`${RECORDS_PATH}/`)[1]
    : decodedPath.split(`${RECORDS_PATH}/`)[1].split("/")[0];
  // console.log(namePart);
  useEffect(() => {
    const navigationJson = generateNavigation();
    // console.log(navigationJson);
    const activeItem = findAndOpenActiveMenuItem(navigationJson, namePart);
    setNavigation(navigationJson);
    if (activeItem) {
      setActiveLink(activeItem.id);
    }
  }, [namePart]);

  const findAndOpenActiveMenuItem = (
    items: MenuItem[],
    path: string,
    parentPath: string = ""
  ): MenuItem | undefined => {
    for (let item of items) {
      const itemPath = parentPath + item.id;
      if (item.id === path) {
        // Open all parent submenus
        parentPath.split("/").forEach((parentId) => {
          if (parentId) {
            setOpenSubmenu((prev) => new Set(prev.add(parentId)));
          }
        });
        return item;
      }
      if (item.children) {
        const found = findAndOpenActiveMenuItem(
          item.children,
          path,
          itemPath + "/"
        );
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  // console.log(openSubmenu);
  const toggleSubmenu = (id: string) => {
    setOpenSubmenu((prev) => {
      const updated = new Set(prev);
      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }
      return updated;
    });
  };

  const handleLinkClick = (item: MenuItem) => {
    toggleSubmenu(item.id);
  };
  const hasSubItems = (item: MenuItem) =>
    item.children && item.children.length > 0;
  const isSubmenuOpen = (item: MenuItem): boolean =>
    openSubmenu.has(item.id) && item.group === true;
  const isLinkActive = (item: MenuItem): boolean => activeLink === item.id;
  const renderMenuItems = (
    items: MenuItem[],
    parentPath = ""
  ): JSX.Element[] => {
    return items.map((item, index) => {
      // Form the path from the item's ID
      const path = !hasSubItems(item) ? `${parentPath}/${item.id}` : item.id;

        const handleLinkNavigate = async (e: any) => {
          e.preventDefault(); 
          // console.log('SIDEBAR');
          await dispatch(crudListApi.util.resetApiState());
          setActiveLink(item.id);
          navigate(`${RECORDS_PATH}/${encodeURIComponent(item.id)}`);
      };
      return (
        <li key={path} className={isLinkActive(item) ? "active" : ""}>
          {hasSubItems(item) ? (
            <>
              <a
                href={`#${path}Submenu`}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(item);
                }}
                data-toggle="collapse"
                className="btn btn-toggle align-items-center rounded collapsed dropdown-toggle"
                aria-expanded={isSubmenuOpen(item)}
              >
                {item.title}
              </a>
              <ul
                className={`collapse btn-toggle-nav list-unstyled fw-normal pb-1 small ${
                  isSubmenuOpen(item) ? "show" : ""
                }`}
                id={`${path}Submenu`}
              >
                {renderMenuItems(item.children, path)}
              </ul>
            </>
          ) : (
            <Link
            onClick={handleLinkNavigate}
            className="btn btn-nontoggle align-items-center rounded collapsed"
            to={`${RECORDS_PATH}/${encodeURIComponent(item.id)}`} // Using href for proper cursor and semantics
            >
              {item.title}
            </Link>
          )}
        </li>
      );
    });
  };

  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <EnvironmentSelector />
      <div className="position-sticky pt-3">
        <ul className="list-unstyled ps-0 sidebar-margin">
          {navigation.length > 0 && renderMenuItems(navigation)}
        </ul>
      </div>
    </nav>
  );
};
export default CollapsibleMenu;
