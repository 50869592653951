import { memo, useEffect, useState } from "react";
import { Field } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { FormElementState, formState, setFormValue } from "../../reducers/formSlice";
import { CONTEXT_UPDATE, fieldCardinalityOpt, fieldName, getStateNameExtra } from "../crud/Base";
import { ColorResult, SketchPicker } from 'react-color';
import { Nullable } from "./nullable";


const rgbObj = (rgb: any, alpha: boolean
) => {
  const obj: any = {};
  Object.keys(rgb).forEach((letter) => {
    // console.log(letter);
    obj[letter] = rgb[letter];
    // console.log(rgb[letter]);
  });
  if (alpha !== true) {
    delete obj['a'];
  }
  return obj;
};

const ObjToRgb = (rgbD: any) => {
  const obj: any = {};
  const rgb = rgbD.map((v: any) => {
    const letter = v.key.split('colour-object-')[1];
    // console.log(letter);
    obj[letter] = v.value;
    return v;

  });

  return obj;
}

const toHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

const rgbToHex = (r: number, g: number, b: number, a?: number) => {
  const normalHex = toHex(r) + toHex(g) + toHex(b);
  return typeof a === 'number' ? normalHex + toHex(a) : normalHex;
}

const hexToRgb = (hex: string) => {
  // Match either 6-digit or 8-digit hexadecimal values
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  
  if (!result) return null;

  const rgb: any = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };

  // Include alpha channel if 8-digit hex code is provided
  if (result[4]) {
    rgb['a'] = parseInt(result[4], 16) / 255; // Normalize alpha to [0, 1] range
  }

  return rgb;
}
interface ColourElement {
  field: Field;
  hex: boolean;
  width: number;
  alpha: boolean;
  extras: string;
  data: any;
};

const ColourElement: React.FC<ColourElement> = (props: ColourElement) => {
  const [nullValue, setNullValue] = useState(false);
  const [value, setValue] = useState<any>({
    colour: {
      r: 0,
      g: 0,
      b: 0,
    }
  });
  const [show, setShow] = useState(true);
  const [changed, setChanged] = useState(false);
  const dispatch = useAppDispatch();
  const { form, context } = useAppSelector(formState);
  const { field, alpha, width, extras, data, hex } = props;
  // console.log(alpha);
  const appendAlpha = (a: number) => {
    if (alpha) {
      setValue({ colour: { ...value.colour, a: a } })
    }
    return value;
  }
  const defaultValue = appendAlpha(alpha ? 1 : 0);
  const initialObj: FormElementState = {
    key: getStateNameExtra(field, extras),
    value: defaultValue,
    error: fieldCardinalityOpt(field) ? [] : ['Field is required!'],
    extras: getStateNameExtra(field, extras)
  }
  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find((f: FormElementState) => f.key === getStateNameExtra(field, extras));
    if (stateValue) {
      const newValue = checked ? defaultValue : null;
      // const errs = validateField(field, '', !checked);
      // setErrors(errs);
      dispatch(setFormValue({ ...stateValue, value: newValue }));
    }
  };
  useEffect(() => {
    if (context === CONTEXT_UPDATE && Object.values(data).length > 0) {
      // console.log(data);
      const rgbVals = hex ? hexToRgb(data.value) : ObjToRgb(data);
      const rgbFormat = { colour: rgbVals };
      // console.log(rgbFormat);
      setValue(rgbFormat);
      dispatch(setFormValue({
        key: fieldName(field),
        value: data.value,
        error: [],
        extras: getStateNameExtra(field, extras)
      }));
      setChanged(true);
    } else {
      dispatch(setFormValue(initialObj));
    }
  }, []);

  const showAction = (e: any) => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }

  const handleChange = (colour: ColorResult & any) => {
    console.log(colour);
    const colourValue = hex ? rgbToHex(colour.rgb.r,colour.rgb.g, colour.rgb.b, alpha? colour.rgb.a: undefined) : rgbObj(colour.rgb, alpha);
    const newVal = { colour: colour.rgb };
    console.log(colourValue);
    dispatch(setFormValue({
      key: getStateNameExtra(field, extras),
      value: colourValue,
      extras: getStateNameExtra(field, extras),
      error: []
    }));
    setValue(newVal);
    setChanged(true);
  }

  const requiredField = !fieldCardinalityOpt(field) && !changed;
  return <div className="row g-1">
    <div className="col-md-1">
      <label className="text-break form-label">
        <small><strong>{fieldName(field)}</strong></small>
      </label>
    </div>
    {fieldCardinalityOpt(field) && (
      <div className="col-md-1">
          <Nullable field={field} checked={nullValue} onChange={nullableClick} />
      </div>
    )}
    <div className={`col-md-${width}`}>
      <div className='colour-popover'>
        <div className='colour-cover' onClick={handleClose}></div>
        <SketchPicker width='30%' disableAlpha={!alpha} color={value.colour} onChange={handleChange} />
      </div>
      {requiredField && (
        <strong>
          <small className='text-danger'> - Field is required!<br /></small>
        </strong>
      )}
      <small className="text-info">Click on the colour box in order to change the colour! Note: there's no colour selected when the page loads!</small>
    </div>
  </div>
};


interface ColourViewer {
  field: Field;
  value: any;
};

const ColourViewerComponent: React.FC<ColourViewer> = (props: ColourViewer) => {
  const [aplhaVal, setAplhaVal] = useState(0);
  const { value, field } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const BoxColour = (props: any) => {
    const { obj, rgbvals } = props;
    // console.log(rgbvals);
    return <div
      style={obj}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && (
        <div
          id="rgb-tooltip"
          style={{
            position: 'absolute',
            padding: '2px 10px',
            marginBottom: '5px',
            color: 'white',
            borderRadius: '3px',
            backgroundColor: 'black', // Example color for tooltip
            ...props.style,
            // Adjust the position as needed
            top: '-5px',
            left: '100%',
          }}
        >
          <span>Hex: #{rgbToHex(rgbvals[0], rgbvals[1], rgbvals[2], rgbVals[3])}</span>
          <br />
          <span>R {rgbvals[0]} </span>
          <span>G {rgbvals[1]} </span>
          <span>B {rgbvals[2]}</span>
        </div>
      )}
    </div>
  }
  const r = value.find((v: any) => v.key.includes('-r'));
  const g = value.find((v: any) => v.key.includes('-g'));
  const b = value.find((v: any) => v.key.includes('-b'));
  const rgbVals = [r.value, g.value, b.value];
  const getStyle = (val: any) => {

    const styles = {
      'width': '40px',
      'height': '40px',
      'borderRadius': '15px',
      'border': '1px solid',
      'marginBottom': '1px',
      // 'background': `rgba(${ Number(r.value) * 255 }, ${ Number(g.value) * 255 }, ${ Number(b.value) * 255 }, ${ 1 })`,
      'background': `rgba(${Number(r.value)}, ${Number(g.value)}, ${Number(b.value)}, ${1})`,
    };

    return styles;
  };

  return <BoxColour obj={getStyle(value)} rgbvals={rgbVals} />

};

export const ColourViewer = memo(ColourViewerComponent);


export default memo(ColourElement);

