import { memo, useEffect, useState } from "react";
import { Field, PrimitiveType, Tuple, TupleItem } from "../crud/Schema";
import { SimpleInputField } from "./input";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { fieldCardinalityOpt, fieldName, getPrimitive, getPrimitiveUnique, getStateNameExtra } from "../crud/Base";
import { loadState } from "../../reducers/schemaSlice";
import { FormElementState, formState, setFormValue } from "../../reducers/formSlice";
import { Nullable } from "./nullable";

interface TupleInput {
  onChange: any;
  position: number;
  type: string;
  defaultValue: any;
};

const TupleInput = (props: TupleInput): JSX.Element => {
  const { type, onChange, defaultValue, position } = props;
  return (
    <div className="col">
      <SimpleInputField type={type} name={position.toString()} onChange={(val: number) => onChange(val, position) } defaultValue={defaultValue}/>
    </div>
  );
}

interface TupleElement {
  field: Field;
  width: number;
  extras: string;
  data: any;
};
const TupleElement: React.FC<TupleElement> = (props: TupleElement) => {
  const [nullValue, setNullValue] = useState(false);
  const [fetched, setFetched] = useState(false);
  const dispatch = useAppDispatch();
  const { field, width, extras, data } = props;
  const schema = loadState();
  const { form, context } = useAppSelector(formState);
  console.log(getPrimitive(schema, field));
  
  const tupleFormat = [...(getPrimitive(schema, field) as Tuple).values].map(
    (item: TupleItem) => { return (getPrimitiveUnique(schema, item.item.Is.path) as PrimitiveType).def.ident === 'String' ? '' : 0 } );

  const [value, setValue] = useState<any>(tupleFormat);

  
  const handleChange = (val: number, position: number) => {
    const tupleCopy = [ ...value ];
    tupleCopy[position] = val;
    setValue(tupleCopy);
    dispatch(setFormValue({
      key: getStateNameExtra(field, extras),
      value: nullValue ? null : [ ...tupleCopy ],
      extras: getStateNameExtra(field, extras),
      error: []
    }));
  };

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find((f: FormElementState) => f.key === getStateNameExtra(field, extras) );
    if (stateValue) {
      dispatch(setFormValue({ ...stateValue, value: checked ? null : tupleFormat }));
    }
  };
  useEffect(() => {
    const initialObj: FormElementState = {
      key: getStateNameExtra(field, extras),
      value: value,
      extras: getStateNameExtra(field, extras),
      error: []
    };
    dispatch(setFormValue(initialObj));
    // if (context === CONTEXT_UPDATE && Object.values(getData).length > 0 && !fetched) {
    //   console.log(getData);
    //   // const stateName = getStateName(field, extra);
    //   // const updateValue = Object.values(getData).find((formVal: any) => { return  formVal.key === stateName });
    //   // const getValue = updateValue.value;
    //   // // console.log(getValue);
    //   // setValue(getValue);
    //   // dispatch(setFormValue({
    //   //   key: stateName,
    //   //   value: getValue,
    //   //   status: 'idle',
    //   //   extra: extra
    //   // }));
    //   setFetched(true);
    // }
  }, []);


  var rows = [];
  for (let i = 0; i < tupleFormat.length; i++ ) {
    let tupleInput  = tupleFormat[i];
    if (tupleFormat.every((elem: any, i: number) => elem !== value[i]) ){
      tupleInput = value[i];
    }
    rows.push(<TupleInput key={i} type={typeof tupleInput} onChange={handleChange} defaultValue={tupleInput} position={i} />)
  };

  return <div className="row g-1">
  <div className="col-md-1">
    <label className="text-break">
      <small><strong>{fieldName(field)}</strong></small>
    </label>
  </div>
  {fieldCardinalityOpt(field) && (
    <div className="col-md-1 d-flex justify-content-center">
       <Nullable field={field} checked={nullValue} onChange={nullableClick} />
    </div>
  )}
  <div className={`col-md-${width}`}>
    <div className="row">
      {rows}
    </div>
  </div>
</div>;
};

export default memo(TupleElement);