import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { useEffect, useState } from "react";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
  });

  useEffect(() => {
      function handleResize() {
          setWindowSize({
              windowWidth: window.innerWidth,
              windowHeight: window.innerHeight
          });
      }
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
