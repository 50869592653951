import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Entity } from '../../components/crud/Schema';
import { checkError, decodeData, serviceName } from '../../components/crud/Base';
import { Backend } from '../../services/api/backend';
import { listQuery } from './listSlice';
import { Filter } from '../../components/fragments/filters';

export interface RelationsArgs {
  cid: string;
  entity: Entity;
  actorName: keyof Backend.ActorTypes;
  filter?: Filter,
};

export const relationApi = createApi({
  reducerPath: 'relationApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    getRelation: builder.query<any, RelationsArgs>({
      queryFn: async (args) => {
        try {
          const loadActor: any = await Backend.loadActorByName(args.actorName, args.cid);
         console.log(listQuery(args.entity, 1, 10, [], args.filter));
          const result = await loadActor[`crud_load`](
            listQuery(args.entity, 1, 10, [], args.filter));
          console.log(result);
          const errors = checkError(result);
          if (errors !== '') {
            return { error: { status: 'CUSTOM_ERROR', error: errors }};
          }

          // Process and return data
          const decoded = decodeData(result.Ok.Rows);
          console.log(decoded);
          return { data: decoded };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

// Export the hook for the getRelations query
export const { useGetRelationQuery } = relationApi;