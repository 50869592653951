import { memo } from "react";
import { Context } from "../../reducers/formSlice";
import { loadState } from "../../reducers/schemaSlice";
import {
  ASSET_TYPE, entityName, entityUniqueIdentifier, fieldCardinalityMany, fieldName, fieldRelation,
  fieldType, getEntityByPath, getPrimitive, getStateNameExtra,
  getTypeData,
  isEnum,
  isEnumValue,
  isListSet,
  isMap,
  isPrimitive,
  isRecord,
  isTuple
} from "../crud/Base";
import { dataExact, dataStart } from "../crud/DataProcessor";
import { Entity, Enum, EnumValue, Field, GenericMap, Newtype, PrimitiveType, RecordType, SchemaTypes } from "../crud/Schema";
import { AssetElement } from "./asset";
import ColourElement from "./colourPicker";
import EnumValueElement from "./enumvalue";
import HiddenElement from "./hidden";
import { InputField, InputSwitch } from "./input";
import ListSetElement  from "./list-set";
import ManyElement from "./many";
import MapElement from "./map";
import RecordElement from "./record";
import { RelationElement } from "./relation";
import TupleElement from "./tuple";
import VariantElement from "./variant";

export interface ElementProps {
  field: Field;
  extras: string;
  data: any[];
  width?: number;
};

export interface FormTypeProps {
  formType: string;
  parentRef?: SchemaTypes;
  alpha?: boolean;
}

export const formTypeValue = (field: Field): FormTypeProps => {
  const schema = loadState();
  let formType = '';
  let alpha = false;
  let parentRef = undefined;
  const fieldTypeValue = fieldType(field);
  // console.log(field);
  // console.log(fieldTypeValue);
  if (fieldTypeValue) {
    const typeDef = getTypeData(schema, field);
    // console.log(typeDef);
    if (isTuple(typeDef)) {
      formType = 'Tuple';
    }
    if (isMap(typeDef)) {
      formType = 'Map';
      parentRef = typeDef;
    }
    if (isRecord(typeDef)) {
      formType = 'Record';
      // TODO: Fix this once there's a usage
      if (entityName((typeDef as RecordType)) === "Rgb" ||
        entityName((typeDef as RecordType)) === "Rgba"
      ) {
        alpha = entityUniqueIdentifier(typeDef) === "Rgba";
        formType = 'Colour';
      }
      parentRef = typeDef;
    }
    if (isEnum(typeDef)) {
      formType = 'dropdown-embed';
      parentRef = typeDef;
    }
    if (typeDef.type === 'Newtype' && (typeDef.id.includes('RgbHex') || typeDef.id.includes('RgbaHex'))) {
      formType = 'ColourHex';
      alpha = typeDef.id.includes('RgbaHex');
    }
    if (isEnumValue(typeDef)) {
      formType = 'EnumValue';
      parentRef = typeDef;
    }
    if (isListSet(typeDef)) {
      parentRef = typeDef;
      formType = 'ListSet';
    }
    if (typeDef.type === 'Newtype' && (typeDef as Newtype).primitive) {
      formType = (typeDef as Newtype).primitive as string;
    }

    if (typeDef.type === 'Primitive') {
      formType = (typeDef as PrimitiveType).ty;
    }
   
    if (['id', 'created', 'modified'].includes(fieldName(field))) {
      formType = 'Hidden';
    }
  }
   // @ts-ignore
   if (fieldTypeValue === 'Id') {
    formType = 'Hidden';
  }
  const relationType = fieldRelation(field);
  if (relationType) {
    formType = 'Relation';
    parentRef = getEntityByPath(schema, relationType);
    if (!parentRef) {
      throw new Error(`Relation ${relationType} not found!`);
    }
    formType = entityUniqueIdentifier(parentRef) === ASSET_TYPE ? 'Asset' : formType;
  }
  if (fieldCardinalityMany(field) && formType !== 'Relation') {
    formType = 'Many';
  }

  return { formType, parentRef, alpha };
}

const FormElement = (props: ElementProps): JSX.Element => {
  const { extras, field, data, width } = props;
  const rowWidth = width ? width : 10;
  const { formType, parentRef, alpha } = formTypeValue(field);
 
  if (formType === '') {
    return <strong className="alert alert-danger">{`Element ${fieldName(field)} has an unknown type in schema!`}</strong>;
  }
  // console.log(formType);
  // console.log(field);
  switch (formType) {
    case 'Many':
      return <ManyElement width={rowWidth} field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-array')} extras={extras} />;
    case 'Hidden':
    case 'Todo':
      return <HiddenElement field={field} data={dataExact(data, getStateNameExtra(field, extras))} extras={extras} />;
    case 'String':
    case 'Text':
      return <InputField type={'text'} field={field} data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Decimal':      
    case 'U8':
    case 'I8':
    case 'U16':
    case 'I16':
    case 'I32':
    case 'I64':
    case 'U64':
    case 'U32':
    case 'U16':
    case 'F8':
    case 'F16':
    case 'F32':
    case 'F64':
    case 'Number':
      return <InputField type={'number'} field={field}
        data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Textarea':
      return <InputField type={'textarea'} field={field}
        data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Bool':
      return <InputSwitch field={field} width={rowWidth}
        data={dataExact(data, getStateNameExtra(field, extras))} extras={extras} />;
    case 'Asset':
      return <AssetElement field={field} width={rowWidth} data={dataExact(data, getStateNameExtra(field, extras))} blobType={false} extras={extras} />;
    case 'Bytes':
      return <AssetElement field={field} width={rowWidth} data={dataExact(data, getStateNameExtra(field, extras))} blobType={false} extras={extras} />;
    case 'EnumValue':
      return <EnumValueElement parentRef={parentRef as EnumValue} field={field} data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Tuple':
      return <TupleElement field={field} width={rowWidth} data={data} extras={extras} />;
    case 'Colour': {
      return <ColourElement hex={false} field={field} width={rowWidth} data={dataStart(data, getStateNameExtra(field, extras) + '-object')} alpha={false} extras={extras} />;
    }
    case 'ColourHex': {
      return <ColourElement hex={true} field={field} width={rowWidth} data={dataExact(data, getStateNameExtra(field, extras))} alpha={false} extras={extras} />;
    }
    // case 'date-picker': {
    //     return <Row className='mh50'><DatePicker { ...elementProps } /></Row>;
    // }
    // case 'date-interval': {
    //     return <Row className='mh50'><DateRangePicker { ...elementProps } /></Row>;
    // }
    case 'Relation':
      if (!parentRef) {
        return <p> Relation field {fieldName(field)} needs a Parent! </p>
      }
      // console.log(parentEntity);
      // console.log(getStateNameExtra(field, extras));
      // console.log(dataExact(data, getStateNameExtra(field, extras)));
      return <RelationElement parentRef={parentRef as Entity} data={dataExact(data, getStateNameExtra(field, extras))} field={field} width={rowWidth} extras={extras} />;
    case 'Record':
      // console.log(parentEntity);
      // console.log(extra);
      if (!parentRef) {
        return <p>Embed field {fieldName(field)} needs a Parent! </p>
      }
      return <RecordElement parentRef={parentRef as RecordType} field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-object')} width={rowWidth} extras={extras} />;
    case 'dropdown-embed':
      return <VariantElement parentRef={parentRef as Enum} field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-')} width={rowWidth} extras={extras} />
    case 'Map':
      if (!parentRef) {
        return <p> Map field {fieldName(field)} needs a Parent! </p>
      }
      return <MapElement parentRef={parentRef as GenericMap} field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-array_key_')} width={rowWidth} extras={extras} ></MapElement>;
    case 'ListSet':
      // console.log(parentRef);
      if (!parentRef) {
        return <p> List/Set field {fieldName(field)} needs a Parent! </p>
      }
      return <ListSetElement parentRef={parentRef as Newtype} data={dataStart(data, getStateNameExtra(field, extras) + '-')} field={field} width={rowWidth} extras={extras} />;
    default:
      return (
        <div className="alert alert-danger" role="alert">{fieldName(field)} : Unknown form type detected : {formType}</div>
      );
  }

}
FormElement.whyDidYouRender = true;
export default memo(FormElement);
