import { fieldName } from "../crud/Base";
import { Field } from "../crud/Schema";

export interface NullableProps {
    field: Field;
    onChange : (e: React.MouseEvent) => void;
    checked : boolean;
};

export const Nullable = ({field, checked, onChange}: NullableProps) => {

    return <div className="onoffswitch">
        <input 
          type="checkbox" 
          id={fieldName(field)}
          name="onoffswitch" 
          className="onoffswitch-checkbox" 
          checked={checked}
          onChange={(e) => onChange(e as any)} 
          onClick={(e) => e.stopPropagation()} 
        />
        <label className="onoffswitch-label" htmlFor={fieldName(field)}>
            <span className="onoffswitch-inner"></span>
            <span className="onoffswitch-switch"></span>
        </label>
      </div>
    
};