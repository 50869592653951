const schema = require("../../../schema/schema.json");
const types: { [key: string]: BaseSchema } = schema.nodes;
export const schemaVersion: string = schema.hash;
export const schemaCreated: string = schema.timestamp;
export type SchemaTypesString =
  | "Entity"
  | "Newtype"
  | "Tuple"
  | "Enum"
  | "EnumValue"
  | "Primitive"
  | "Map"
  | "Record"
  | "Canister"
  | "Store";

export interface Schema {
  [typeName: string]:
    | Entity
    | Newtype
    | Tuple
    | Enum
    | EnumValue
    | PrimitiveType
    | GenericMap
    | RecordType;
}

export type SchemaTypes =
  | Entity
  | Newtype
  | Tuple
  | Enum
  | EnumValue
  | PrimitiveType
  | GenericMap
  | RecordType;

export interface BaseSchema {
  [typeName: string]:
    | EntityBase
    | NewtypeBase
    | TupleBase
    | EnumBase
    | EnumValueBase
    | PrimitiveBase
    | GenericMapBase
    | RecordBase
    | CanisterType
    | StoreType;
}

export type BaseSchemaTypes =
  | EntityBase
  | NewtypeBase
  | TupleBase
  | EnumBase
  | EnumValueBase
  | PrimitiveBase
  | GenericMapBase
  | RecordBase;

export type Def = {
  ident: string;
  module_path: string;
  comments?: string;
  generics?: string;
  ty?: string;
};

export type EntityDisplay = string[];

export type Order = {
  field: string;
  direction: string;
};

export type RecordOrder = Order[];

export type GenericType = {
  [key: string]: string | number;
};

export type GenericString = {
  [key: string]: string;
};

export type GenericNumber = {
  [key: string]: number;
};


export type Value = {
  item: Item;
  cardinality: string;
  default?: GenericNestedObject;
};

export type Item = {
  Is: {
    path: string;
    generics?: GenericType[];
  };
};

export interface Validator {
  path: string; // Since rule can be various types like 'range', 'length', etc.
  args?: GenericNestedObject[]; // An array of arguments, structure defined below
}

export interface GenericNestedObject {
  [key: string]: {
    [nestedKey: string]: number | string | boolean; // Number or string can be the type of the value inside the argument object
  };
}

export type Traits = {
  kind?: string;
  args?: GenericType[];
};

export type Sanitizers = {
  path?: string;
  args?: GenericType[];
};

export interface NewtypeBase {
  def: Def;
  value: Value;
  primitive?: string;
  sanitizers?: Sanitizers;
  validators?: Validator[];
}

export interface TupleItem {
  cardinality: string;
  item: Item;
}

export interface TupleBase {
  def: Def;
  values: TupleItem[];
}

export interface IsOrEmbedType {
  Is?: {
    path: string;
  };
  Record?: {
    path: string;
  };
  Embed?: {
    path: string;
  };
  Relation?: {
    path: string;
  };
}

export interface EnumVariant {
  name: string;
  value?: {
    cardinality: string;
    item: IsOrEmbedType;
  };
  default?: boolean;
  rename?: string;
  invalid?: boolean;
  unspecified?: boolean;
}

export interface EnumValueVariant {
  name: string;
  value: string | number;
  default?: boolean;
  unspecified?: boolean;
}

export interface EnumBase {
  def: Def;
  validators?: Validator[];
  traits: Traits[];
  variants: EnumVariant[];
}

export interface EnumValueBase {
  def: Def;
  variants: EnumValueVariant[];
}


export interface PrimitiveBase {
  def: Def;
  ty: string;
}

export interface GenericTyBase {
  def: Def;
  item: Item;
  validators?: Validator[];
  traits: Traits[];
}

// export type GenericListBase = GenericTyBase;

// export type GenericSetBase = GenericTyBase;

export interface GenericMapBase {
  def: Def;
  key: IsOrEmbedType;
  validators?: Validator[];
  traits: Traits[];
  value: {
    item: Item;
    cardinality: string;
  };
}

export interface Field {
  name: string;
  value: FieldItem;
  skip_validate?: boolean;
}

export interface FieldItem {
  cardinality: string;
  item: IsOrEmbedType | string;
  default?: GenericNestedObject | GenericType;
}

export interface RecordBase {
  def: Def;
  fields: { fields: Field[]; order?: RecordOrder };
  display?: EntityDisplay;
  hash?: string;
}

export interface SortKey {
  entity: string;
  field: string[];
}

export interface Index {
  fields: string[];
  unique?: boolean;
}


export interface Crud {
  load: string | GenericString;
  save?: string | GenericString;
  delete?: string | GenericString;
}

export interface EntityBase {
  def: Def;
  store: string;
  fields: { fields: Field[]; order: RecordOrder };
  primary_keys?: string[];
  crud?: Crud;
  repo?: string;
  sort_keys?: SortKey[];
  indexes?: Index[];
}

export interface CanisterType {
  def: Def;
  build: string | { Basic: { replicated: boolean } };
}

export interface StoreType {
  def: Def;
  canister: string;
  memory_id: number;
  crud?: Crud;
}

// re-exported type for admin only
export type EntityStore = {
  // actor data
  is_distributed: boolean;
  display?: EntityDisplay;
  storeName: string;
  memory_id: number;
  actorName: string;
  actorPath: string;
};

export type GenericTypeName = {
  type: SchemaTypesString;
  id: string;
};

export type Newtype = NewtypeBase & GenericTypeName;
export type Entity = EntityBase & EntityStore & GenericTypeName;
export type RecordType = RecordBase & GenericTypeName;
export type Tuple = TupleBase & GenericTypeName;
export type Enum = EnumBase & GenericTypeName;
export type EnumValue = EnumValueBase & GenericTypeName;
export type PrimitiveType = PrimitiveBase & GenericTypeName;
export type GenericMap = GenericMapBase & GenericTypeName;

const isSchemaTypesString = (key: any): key is SchemaTypesString => {
  const validTypes: SchemaTypesString[] = [
    "Entity",
    "Newtype",
    "Tuple",
    "Enum",
    "EnumValue",
    "Primitive",
    "Map",
    "Record",
  ];
  return validTypes.includes(key);
};

const getStore = (storeName: string): StoreType | undefined => {
  for (let key of Object.keys(types)) {
    const typeObj: BaseSchema = types[key];
    const typeKey: any = Object.keys(typeObj)[0];
    if (typeKey === "Store" && key === storeName) {
      const typeData: any = typeObj[typeKey];
      return typeData;
    }
  }
};

const getCanister = (canisterName: string): CanisterType | undefined => {
  for (let key of Object.keys(types)) {
    const typeObj: BaseSchema = types[key];
    const typeKey: any = Object.keys(typeObj)[0];
    if (typeKey === "Canister" && key === canisterName) {
      const typeData: any = typeObj[typeKey];
      return typeData;
    }
  }
};

export const parsedTypes = () => {
  const schema: Schema = {};
  for (let key of Object.keys(types)) {
    const typeObj: BaseSchema = types[key];

    const typeKey: any = Object.keys(typeObj)[0];
    const typeData = typeObj[typeKey];

    if (!isSchemaTypesString(typeKey)) {
      continue;
    }

    if (typeKey === "Entity") {
      // console.log(key);
      // console.log(typeKey);
      // console.log(typeData);
      const storeInfo = getStore((typeData as EntityBase).store);
      if (!storeInfo) {
        throw new Error(
          `Store ${(typeData as EntityBase).store} not found in schema`
        );
      }
      const canisterInfo = getCanister(storeInfo.canister);
      if (!canisterInfo) {
        throw new Error(`Canister ${storeInfo.canister} not found in schema`);
      }
      let crudData = (typeData as Entity).crud;
      if (crudData === null || crudData === undefined) {
        crudData = storeInfo.crud;
      } else {
        if (crudData.load === 'Allow'){
          crudData.load = (storeInfo.crud as any)?.load;
        }
        if (crudData.save === 'Allow'){
          crudData.save = (storeInfo.crud as any)?.save;
        }
        if (crudData.delete === 'Allow'){
          crudData.delete = (storeInfo.crud as any)?.delete;
        }
      }
      // if (key === "design::asset::model::Model") {
      //   console.log(crudData);
      //   console.log(storeInfo);
      // }
      const entityStoreData: EntityStore = {
        is_distributed: typeof canisterInfo.build === "object" && canisterInfo.build.Basic.replicated,
        storeName: storeInfo.def.ident,
        memory_id: storeInfo.memory_id,
        actorName: canisterInfo.def.ident,
        actorPath: storeInfo.canister,
      };
      // @ts-ignore
      schema[key] = {
        type: "Entity",
        ...typeData,
        ...entityStoreData,
        crud: crudData,
        id: key,
      };
      continue;
    }
    // @ts-ignore
    schema[key] = {
      id: key,
      type: typeKey,
      ...typeData,
    };
  }

  return schema;
};

export const getSchemaVersion = (): string => {
  return schemaVersion;
};

export const getSchemaTimestamp = (): string => {
  return schemaCreated;
};

export const isGenericType = (arg: any): arg is GenericType => {
  if (typeof arg !== "object" || arg === null) {
    return false; // Not an object or is null
  }

  return Object.values(arg).every(
    (value) => typeof value === "string" || typeof value === "number" || typeof value === "boolean"
  );
};

const isGnericNestedObject = (arg: any): arg is GenericNestedObject => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  return Object.entries(arg).every(([key, value]) => {
    if (typeof value !== "object" || value === null) {
      return false;
    }
    return Object.values(value).every(nestedValue =>
      typeof nestedValue === "number" ||
      typeof nestedValue === "string" ||
      typeof nestedValue === "boolean" // Ensure this check includes boolean
    );
  });
};

export const isValidator = (arg: any): arg is Validator => {
  // Validate that arg is an object, not null, and has a string type path
  if (typeof arg !== "object" || arg === null || typeof arg.path !== "string") {
    return false;
  }

  // Validate the optional args array, if present
  if (arg.args !== undefined) {
    if (!Array.isArray(arg.args) || !arg.args.every(isGenericNestedObject)) {
      return false;
    }
  }

  return true;
};

export const isGenericNestedObject = (arg: any): arg is GenericNestedObject => {
  // Implementation depends on the structure of GenericNestedObject
  return typeof arg === "object" && arg !== null; // Simplified example
};

const isItem = (arg: any): arg is Item => {
  return (
    typeof arg === "object" &&
    arg !== null &&
    arg.Is &&
    typeof arg.Is === "object" &&
    typeof arg.Is.path === "string" &&
    (arg.Is.generics === undefined || Array.isArray(arg.Is.generics))
  );
};

export const isValueValid = (value: any): value is Value => {
  const isDefaultValid =
    value.default === undefined ||
    isGenericType(value.default) ||
    isGnericNestedObject(value.default);
  return (
    typeof value === "object" &&
    value !== null &&
    isItem(value.item) &&
    isDefaultValid &&
    typeof value.cardinality === "string"
  );
};

export const isDef = (arg: any): arg is Def => {
  const validKeys = ["ident", "generics", "ty", "comments", "module_path"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  return (
    hasNoExtraKeys &&
    typeof arg.ident === "string" &&
    typeof arg.module_path === "string" && // Now correctly validating mandatory 'module_path'
    (typeof arg.comments === "string" || arg.comments === undefined) &&
    (arg.generics === undefined || typeof arg.generics === "string") &&
    (arg.ty === undefined || typeof arg.ty === "string")
  );
};
export const isGenericNumber = (arg: any): arg is GenericNumber => {
  if (typeof arg !== "object" || arg === null) return false;
  // Add logic to validate the structure of GenericNumber
  return Object.keys(arg).every(
    (key) => typeof key === "string" && typeof arg[key] === "number"
  );
};

export const isTraits = (arg: any): arg is Traits[] => {
  if (!Array.isArray(arg)) {
    return false;
  }

  // Check each trait in the array
  for (const trait of arg) {
    if (typeof trait !== "object" || trait === null) {
      return false;
    }

    const validKeys = ["kind", "args"];
    const hasNoExtraKeys = Object.keys(trait).every((key) =>
      validKeys.includes(key)
    );

    if (!hasNoExtraKeys || typeof trait.kind !== "string") {
      return false;
    }

    if (trait.args !== undefined && !Array.isArray(trait.args)) {
      return false;
    }
  }

  return true;
};

export const isSanitizers = (arg: any): arg is Sanitizers => {
  if (typeof arg !== "object" || arg === null) return false;

  const validKeys = ["path", "args"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  if (
    (arg.path !== undefined && typeof arg.path !== "string") ||
    (arg.args !== undefined && !Array.isArray(arg.args))
  ) {
    return false;
  }

  return hasNoExtraKeys;
};

export const isNewtypeBase = (arg: any): arg is NewtypeBase => {
  if (typeof arg !== "object" || arg === null) return false;

  const mainKeys = [
    "value",
    "def",
    "sanitizers",
    "guide",
    "validators",
    "primitive",
  ];
  const hasNoExtraMainKeys = Object.keys(arg).every((key) =>
    mainKeys.includes(key)
  );
  const isPrimitiveValid = typeof arg.primitive === "undefined" || typeof arg.primitive === "string";
  const isValueValidated = isValueValid(arg.value);
  const isDefValid = isDef(arg.def);

  const isSanitizersValid =
    typeof arg.sanitizers === "undefined" ||
    (Array.isArray(arg.sanitizers) && arg.sanitizers.every(isSanitizers));
  const isValidatorsValid =
    typeof arg.validators === "undefined" ||
    (Array.isArray(arg.validators) && arg.validators.every(isValidator));
  // console.log(
  //   isPrimitiveValid,
  //   isValueValidated,
  //   isDefValid,
  //   isGuideValid,
  //   isSanitizersValid,
  //   isValidatorsValid
  // );
  return (
    hasNoExtraMainKeys &&
    isValueValidated &&
    isValidatorsValid &&
    isSanitizersValid &&
    isPrimitiveValid &&
    isDefValid
  );
};

const isTupleItem = (arg: any): arg is TupleItem => {
  const validKeys = ["cardinality", "item"];

  // Check for no extra properties
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  return (
    hasNoExtraKeys && typeof arg.cardinality === "string" && isItem(arg.item)
  );
};

export const isTupleBase = (arg: any): arg is TupleBase => {
  const validKeys = ["values", "def"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const isDefValid = isDef(arg.def);
  const isVariantFieldValid =
    Array.isArray(arg.values) && arg.values.every(isTupleItem);

  return hasNoExtraKeys && isVariantFieldValid && isDefValid;
};

const isIsOrEmbedType = (arg: any): arg is IsOrEmbedType => {
  if (typeof arg !== "object" || arg === null) return false;

  const validKeys = ["Is", "Record", "Embed", "Relation"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  return (
    hasNoExtraKeys &&
    ((arg.Is !== undefined && typeof arg.Is.path === "string") ||
      (arg.Record !== undefined && typeof arg.Record.path === "string") ||
      (arg.Relation !== undefined && typeof arg.Relation.path === "string") ||
      (arg.Embed !== undefined && typeof arg.Embed.path === "string"))
  );
};

const isEnumVariant = (arg: any): arg is EnumVariant => {
  if (typeof arg !== "object" || arg === null) return false;

  const validKeys = ["name", "value", "default", "rename", "invalid", 'unspecified'];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  // console.log(hasNoExtraKeys)
  return (
    hasNoExtraKeys &&
    typeof arg.name === "string" &&
    (arg.default === undefined || typeof arg.default === "boolean") &&
    (arg.rename === undefined || typeof arg.rename === "string") &&
    (arg.invalid === undefined || typeof arg.invalid === "boolean") &&
    (arg.value === undefined ||
      (typeof arg.value === "object" &&
        arg.value !== null &&
        typeof arg.value.cardinality === "string" &&
        isIsOrEmbedType(arg.value.item)))
  );
};

export const isEnumBase = (arg: any): arg is EnumBase => {
  if (typeof arg !== "object" || arg === null) return false;

  const validKeys = ["variants", "def"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const isEnumVariantValid = Array.isArray(arg.variants) && arg.variants.every(isEnumVariant);
  const isDefValid = isDef(arg.def);
  // console.log(hasNoExtraKeys, isEnumVariantValid, isDefValid);
  return hasNoExtraKeys && isEnumVariantValid && isDefValid;
};

export const isPrimitiveBase = (arg: any): arg is PrimitiveBase => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  const validKeys = ["def", "ty"]; // Updated to include all properties from PrimitiveBase
  const hasRequiredKeys = validKeys.every((key) => key in arg);
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  const isDefValid = isDef(arg.def);
  const isTyValid = typeof arg.ty === "string"; // Ensure 'ty' is a string

  return hasRequiredKeys && hasNoExtraKeys && isDefValid && isTyValid;
};

export const isGenericTyBase = (arg: any): arg is GenericTyBase => {
  if (typeof arg !== "object" || arg === null) return false;

  const validKeys = ["item", "traits", "validators", "def"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const itemValid = isItem(arg.item);
  const isDefValid = isDef(arg.def);
  const isTraitsValid =
    typeof arg.traits === "undefined" || isTraits(arg.traits);
  const isValidatorsValid =
    typeof arg.validators === "undefined" ||
    (Array.isArray(arg.validators) && arg.validators.every(isValidator));
  return (
    hasNoExtraKeys &&
    itemValid &&
    isTraitsValid &&
    isDefValid &&
    isValidatorsValid
  );
};

// export const isGenericListBase = (arg: any): arg is GenericListBase => {
//   const validKeys = ["def", "item", "traits", "validators", "comments"];
//   const hasNoExtraKeys = Object.keys(arg).every((key) =>
//     validKeys.includes(key)
//   );

//   const isDefValid = isDef(arg.def);
//   const itemValid = isItem(arg.item);

//   const isTraitsValid =
//     typeof arg.traits === "undefined" || isTraits(arg.traits);
//   const isValidatorsValid =
//     typeof arg.validators === "undefined" ||
//     (Array.isArray(arg.validators) && arg.validators.every(isValidator));
//   const commentsValid =
//     typeof arg.comments === "undefined" || typeof arg.comments === "string";

//   return (
//     hasNoExtraKeys &&
//     isDefValid &&
//     itemValid &&
//     isTraitsValid &&
//     isValidatorsValid &&
//     commentsValid
//   );
// };

export const isGenericMapBase = (arg: any): arg is GenericMapBase => {
  if (typeof arg !== "object" || arg === null) return false;
  const validKeys = ["key", "value", "def", "traits", "validators"];
  const hasRequiredKeys = "key" in arg && "value" in arg;

  const isKeyValid = isIsOrEmbedType(arg.key);

  const isValueValid =
    typeof arg.value === "object" &&
    arg.value !== null &&
    (isItem(arg.value.item) || typeof arg.value.item === "string") &&
    typeof arg.value.cardinality === "string";

  const isTraitsValid =
    typeof arg.traits === "undefined" || isTraits(arg.traits);
  const isValidatorsValid =
    typeof arg.validators === "undefined" ||
    (Array.isArray(arg.validators) && arg.validators.every(isValidator));

  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  const isDefValid = isDef(arg.def);

  return (
    hasRequiredKeys &&
    isDefValid &&
    isKeyValid &&
    isValueValid &&
    isTraitsValid &&
    isValidatorsValid &&
    hasNoExtraKeys
  );
};

export const isField = (arg: any): arg is Field => {
  const validKeys = ["name", "value", "skip_validate", "order"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const isNameValid = typeof arg.name === "string";
  const isValueValid = isFieldItem(arg.value); // Assuming isFieldItem validates the FieldItem structure
  const isSkipValidateValid =
    arg.skip_validate === undefined || typeof arg.skip_validate === "boolean";
  // console.log(arg.value);
  // console.log(hasNoExtraKeys, isNameValid, isValueValid, isSkipValidateValid);
  return hasNoExtraKeys && isNameValid && isValueValid && isSkipValidateValid;
};

export const isFieldItem = (arg: any): arg is FieldItem => {
  const validKeys = ["cardinality", "item", "default"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const isDefaultValid =
    arg.default === undefined || isGnericNestedObject(arg.default) || isGenericType(arg.default);
    // console.log(arg.default);
  // console.log(hasNoExtraKeys, isDefaultValid, isIsOrEmbedType(arg.item));
  return (
    hasNoExtraKeys &&
    typeof arg.cardinality === "string" &&
    isDefaultValid &&
    isIsOrEmbedType(arg.item) || typeof arg.item === 'string'
  );
};

export const isRecordBase = (arg: any): arg is RecordBase => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  const isDefValid = isDef(arg.def);
  const areFieldsValid =
    typeof arg.fields === "object" &&
    Array.isArray(arg.fields.fields) &&
    arg.fields.fields.every(isField) &&
    (arg.fields.order === undefined || isRecordOrder(arg.fields.order));
  const isDisplayValid =
    arg.display === undefined || isEntityDisplay(arg.display);
  const isHashValid = arg.hash === undefined || typeof arg.hash === "string";
  const validKeys = ["def", "fields", "display", "hash"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  // console.log(isDefValid, areFieldsValid, hasNoExtraKeys, isDisplayValid, isHashValid);
  return (
    isDefValid &&
    areFieldsValid &&
    hasNoExtraKeys &&
    isDisplayValid &&
    isHashValid
  );
};

export const isSortKey = (arg: any): arg is SortKey => {
  if (typeof arg !== "object" || arg === null) {
    return false; // Ensure that arg is an object and not null
  }

  if (typeof arg.entity !== "string") {
    return false; // Validate that 'entity' is a string
  }

  if (
    !Array.isArray(arg.fields) ||
    !arg.fields.every((f: any) => typeof f === "string")
  ) {
    return false; // Validate that 'field' is an array of strings
  }

  return true; // Return true if all checks are passed
};

export const isEntityDisplay = (arg: any): arg is EntityDisplay => {
  return Array.isArray(arg) && arg.every((item) => typeof item === "string");
};

export const isOrder = (arg: any): arg is Order => {
  if (typeof arg !== "object" || arg === null) {
    return false;
  }

  const validKeys = ["field", "direction"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const isFieldValid = typeof arg.field === "string";
  const isOrderingValid = typeof arg.direction === "string";

  return hasNoExtraKeys && isFieldValid && isOrderingValid;
};

export const isRecordOrder = (arg: any): arg is RecordOrder => {
  return Array.isArray(arg) && arg.every(isOrder);
};

export const isIndex = (arg: any): arg is Index => {
  if (typeof arg !== "object" || arg === null) {
    return false;
  }

  const validKeys = ["fields", "unique"];
  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );
  const areFieldsValid =
    Array.isArray(arg.fields) &&
    arg.fields.every((field: string) => typeof field === "string");
  const isUniqueValid =
    arg.unique === undefined || typeof arg.unique === "boolean";

  return hasNoExtraKeys && areFieldsValid && isUniqueValid;
};

const isGenericString = (arg: any): arg is GenericString => {
  if (typeof arg !== "object" || arg === null) {
    return false;
  }
  return Object.values(arg).every(value => typeof value === "string");
};

const isCrudValid = (arg: EntityBase): boolean => {
  if (arg.crud === null || arg.crud === undefined) {
    return true;  // Return true if crud is explicitly null or undefined
  }

  if (typeof arg.crud !== "object" || arg.crud === null) {
    console.log("Failed: crud is not an object or is null");
    return false;  // Return false if crud is not an object or if it's null
  }

  const isLoadValid = arg.crud.load === null || 
                      typeof arg.crud.load === "string" || 
                      isGenericString(arg.crud.load);

  const isSaveValid = arg.crud.save === undefined || 
                      arg.crud.save === null || 
                      typeof arg.crud.save === "string" || 
                      isGenericString(arg.crud.save);

  const isDeleteValid = arg.crud.delete === undefined || 
                        arg.crud.delete === null || 
                        typeof arg.crud.delete === "string" || 
                        isGenericString(arg.crud.delete);

  return isLoadValid && isSaveValid && isDeleteValid;
};

export const isStoreType = (arg: any): arg is StoreType => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  if (typeof arg.canister !== "string") {
    console.log("Failed: canister is not a string");
    return false;
  }

  if (typeof arg.memory_id !== "number") {
    console.log("Failed: memory_id is not a number");
    return false;
  }

  if (!isDef(arg.def)) {
    console.log("Failed: def is invalid");
    return false;
  }

  if (!isCrudValid(arg)) {
    console.log("Failed: crud structure is invalid");
    return false;
  }

  return true;
};

export const isCanisterType = (arg: any): arg is CanisterType => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  if (!isDef(arg.def)) {
    console.log("Failed: def property is invalid");
    return false;
  }

  if (!validateBuild(arg.build)) {
    console.log("Failed: build property is invalid");
    return false;
  }

  return true;
};

function validateBuild(build: any): boolean {
  if (typeof build === "string") {
    return true;
  }
  if (typeof build === "object" && build !== null) {
    if (typeof build.Basic === "object" && build.Basic !== null) {
      return typeof build.Basic.replicated === "boolean";
    }
  }
  return false;
}

export const isEntityBase = (arg: any): arg is EntityBase => {
  if (typeof arg !== "object" || arg === null) {
    console.log("Failed: arg is not an object or is null");
    return false;
  }

  const validKeys = [
    "def",
    "store",
    "fields",
    "primary_keys",
    "crud",
    "sort_keys",
    "repo",
    "indexes",
    "sources"
  ];

  const hasNoExtraKeys = Object.keys(arg).every((key) =>
    validKeys.includes(key)
  );

  const isDefValid = isDef(arg.def);
  const isStoreValid = typeof arg.store === "string";
  const isRepoValid = arg.repo === undefined || typeof arg.repo === "string";
  const areFieldsValid =
    typeof arg.fields === "object" &&
    Array.isArray(arg.fields.fields) &&
    arg.fields.fields.every(isField);
  // console.log(arg.fields.order);
  const areFieldsOrderValid = arg.fields.order === undefined || isRecordOrder(arg.fields.order);
  const isPrimaryKeyValid =
    arg.primary_keys === undefined ||
    (Array.isArray(arg.primary_keys) &&
      arg.primary_keys.every((pk: any) => typeof pk === "string"));
  const isCrud = isCrudValid(arg);
  const areSortKeysValid =
    arg.sort_keys === undefined ||
    (Array.isArray(arg.sort_keys) && arg.sort_keys.every(isSortKey));
  const isIndexesValid =
    arg.indexes === undefined ||
    (Array.isArray(arg.indexes) && arg.indexes.every(isIndex));

  // console.log(
  //   hasNoExtraKeys,
  //   isDefValid,
  //   isStoreValid,
  //   areFieldsValid,
  //   areFieldsOrderValid,
  //   isPrimaryKeyValid,
  //   isCrud,
  //   areSortKeysValid,
  //   isIndexesValid,
  //   isRepoValid
  // );

  return (
    hasNoExtraKeys &&
    isDefValid &&
    isStoreValid &&
    areFieldsValid &&
    areFieldsOrderValid &&
    isPrimaryKeyValid &&
    isCrud &&
    areSortKeysValid &&
    isIndexesValid &&
    isRepoValid
  );
};

export const getJsonByType = (
  type: SchemaTypesString,
  validation: (item: any) => boolean
): BaseSchemaTypes[] => {
  const incorrectItem: any = {};
  for (const key of Object.keys(types)) {
    if (Object.values(incorrectItem).length > 0) {
      break;
    }
    const allData: any = types[key];
    const typeStr: string = Object.keys(allData)[0];
    if (type === typeStr) {
      const item = allData[typeStr];
      // console.log(item);
      if (!validation(item)) {
        incorrectItem[key] = item;
      }
    }
  }

  // console.log(incorrectItem);
  return incorrectItem;
};
