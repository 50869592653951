import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/store";

export interface Context {
  context: 'Create' | 'Update';
}

interface InitialState {
  context: Context['context'];
  name: string;
}

export interface ListState {
  name: string;
  data: Array<any>;
  error?: string;
}

const initialListState: ListState = {
  name: '',
  data: [],
  error: '',
};

export interface FormElementState {
  key: string;
  value: any;
  error : string[],
  pre?: any;
  post? : any;
  extras: string;
};

export interface FormState {
  context: Context['context'];
  name: string;
  form: FormElementState[];
  relations: ListState[];
}

const initialFormState: FormState = {
  context: 'Create',
  name: '',
  form: [],
  relations: [],
};

export const formSlice = createSlice({
  name: 'form',
  initialState: initialFormState,
  reducers: {
    setFormName(state, action: PayloadAction<InitialState>) {
      state.name = action.payload.name;
      state.context = action.payload.context;
    },
    filterAndUpdate: (state, action: PayloadAction<string>) => {
      const rest = state.form.filter((form: FormElementState) => { return !form.key.includes(action.payload); });
      return { ...state, form: rest };
    },
    deleteByKey: (state, action: PayloadAction<string>) => {
      // console.log(action.payload);
      const rest = state.form.filter((form: FormElementState) => { return form.key !== action.payload; });
      return { ...state, form: rest };
    },
    clearFormValue: (state, action: PayloadAction<string>) => {
      console.log(action.payload);
      const rest = state.form.filter((form: FormElementState) => { return !form.key.includes(action.payload); });
      return { ...state, form: rest };
    },
    clearForm :(state) => {
        state.context = initialFormState.context;
        state.name = initialFormState.name;
        state.form = initialFormState.form;
        state.relations = initialFormState.relations;
    },
    setFormValue: (state, action: PayloadAction<FormElementState>) => {
      const field = state.form.find((form: FormElementState) => form.key === action.payload.key);
      if (field) {
          return { ...state, form: state.form.map(
              (formField) => {
                const field = formField.key === action.payload.key ?
              {
              ...formField,
                value: action.payload.value,
                error: action.payload.error,
                pre: action.payload.pre,
                post: action.payload.post,
              } : formField;
                return field }) };
      }
      return { ...state, form: [...state.form, action.payload ] }
    },
  },
});

export const listFormSlice = createSlice({
  name: 'list',
  initialState: initialListState,
  reducers: {
    clearList : (state) => {
        state = initialListState;
    },
    setListName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    }
  }
});

export const { setFormName, filterAndUpdate, deleteByKey, clearFormValue, clearForm, setFormValue } = formSlice.actions;

export const formState = (state: RootState) => state.form;