import './wdyr';
import './assets/scss/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app/components/App';
import { Provider } from 'react-redux';
import { store } from './app/services/store';
import { BrowserRouter } from 'react-router-dom';

type EnvironmentContextType = {
  environment: string;
  setEnvironment: React.Dispatch<React.SetStateAction<string>>;
};

const defaultEnvironmentContextValue: EnvironmentContextType = {
  environment: "mainnet",
  setEnvironment: () => {},
};

export const EnvironmentContext = React.createContext(defaultEnvironmentContextValue);

export const EnvironmentProvider = ({ children }: any) => {
  const envs = process.env.NODE_ENV  === 'development' ? 'local' : 'mainnet';
  const [environment, setEnvironment] = React.useState(envs); // default value

  return (
    <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
      <EnvironmentProvider>
        <App />
      </EnvironmentProvider>
    </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

