import { memo } from 'react';
import ls from '../../../assets/img/dfn-sml.gif';

interface LoadingSpinnerProps {
    /**
     * Alt text to be assigned to the loading image alt text attribute for accessibility
     * Should be set to an empty string when the image is decorative and explained by surrounding text where used.
     */
    altText: string;
    theme?: string;
}

/**
 *
 * @constructor
 */
const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => {
    let className = 'lg';
    if (props.theme !== undefined && props.theme !== '') { 
      className = props.theme;
    }
  
    return (
      <div className="d-flex justify-content-center align-items-center w-100">
        <img src={ls} alt="Your Alt Text" className={`spinner loading-spinner-${className}`} />
      </div>
    );
}

export default memo(LoadingSpinner);