import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Backend, Query } from '../../services/api/backend';
import { checkError, decodeData, deserialiseData, entityName, entityUniqueIdentifier, serviceName } from '../../components/crud/Base';
import { Entity } from '../../components/crud/Schema';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Filter } from '../../components/fragments/filters';


export interface GetList {
  entity: Entity;
  actorName: keyof Backend.ActorTypes;
  cid: string;
  page: number;
  size: number;
  pks: string[];
  filter?: Filter;
}

export interface GetAll {
  entity: Entity;
  actorName: keyof Backend.ActorTypes;
  cid: string;
}


export interface CountList {
  e: Entity;
  cid: string;
}

export const listQuery = (
  entity: Entity,
  page: number,
  size: number,
  pks: string[],
  filter?: Filter,
) => {
  // console.log(pks);
  // console.log(size * (page - 1));
  // console.log(size * page);
  const method = pks.length > 0 ? { Prefix: pks } : { All: null };
  const obj: Query = {
    entity: entityUniqueIdentifier(entity),
    method: method,
    offset: size * (page - 1),
    order: [],
    limit: [size],
    filter: [],
    format: {
      Rows: null,
    }
  };
  if (filter && Object.values(filter).length > 0) {
    obj.filter = [filter];
  }

  return obj;
}


export const crudListApi = createApi({
  reducerPath: 'crudListApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  tagTypes: ['List', 'Count'],
  endpoints: (builder) => ({
    getList: builder.query<Array<any>, GetList>({
      queryFn: async (args) => {
        try {
          const loadActor: any = await Backend.loadActorByName(args.actorName, args.cid as string);
          const obj = listQuery(args.entity, args.page, args.size, args.pks, args.filter);
          console.log('%c GET LIST CALLED!! ', 'background: #222; color: #bada55');
          console.log(obj);
          const result = await loadActor[`crud_load`](obj);
          // console.log(result);

          // Check for errors
          const errors: string = checkError(result);
          // console.log(errors);
          if (errors !== '') {
            return { error: { status: 'CUSTOM_ERROR', error: errors } };
          }
          // console.log(result.Ok.Rows);
          // Process and return data
          const dataJson = deserialiseData(decodeData(result.Ok.Rows));
          console.log(dataJson);
          return { data: dataJson } as QueryReturnValue<any[], FetchBaseQueryError>;
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message } };
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' } };
        }
      },
      providesTags: ['List'],
    }),
    getAll: builder.query<Array<any>, GetAll>({
      queryFn: async (args) => {
        try {
          // console.log(args);
          const loadActor: any = await Backend.loadActorByName(args.actorName, args.cid as string);
          const result = await loadActor[`store_keys`]('Data');
          // Check for errors
          const errors: string = checkError(result);
          // console.log(errors);
          if (errors !== '') {
            return { error: { status: 'CUSTOM_ERROR', error: errors } };
          }
          console.log(result);
          // Process and return data
          const dataJson = decodeData(result.Ok.Rows);
          console.log(dataJson);
          return { data: dataJson } as QueryReturnValue<any[], FetchBaseQueryError>;
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message } };
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' } };
        }
      },
      providesTags: ['List'],
    }),
    totalCount: builder.query<number, CountList>({
      queryFn: async (args, queryApi, extraOptions, fetchWithBQ) => {
        try {
          const loadActor: any = await Backend.loadActorByName(serviceName(args.e) as keyof Backend.ActorTypes, args.cid as string);
          const obj: Query = {
            entity: entityUniqueIdentifier(args.e),
            method: {
              All: null
            },
            limit: [],
            order: [],
            filter: [],
            offset: 0,
            format: {
              Count: null,
            }
          }
          console.log(obj);
          console.log('%c GET COUNT CALLED!! ', 'background: #222; color: #bada55');
          const resultScan = await loadActor[`crud_load`](obj);
          // const resultScan = {"Ok": {"Count": 0}};
          console.log(resultScan);
          // Check for errors
          const errors: string = checkError(resultScan);
          if (errors !== '') {
            return { error: { status: 'CUSTOM_ERROR', error: errors } };
          }
          // Process and return data
          console.log(resultScan.Ok);
          return { data: resultScan.Ok.Count } as QueryReturnValue<number, FetchBaseQueryError>;
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message } };
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' } };
        }
      },
      providesTags: ['Count'],
    }),
    refetchList: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['List', 'Count'],
    }),
  }),
});

export const { useGetListQuery, useTotalCountQuery, useRefetchListMutation, useGetAllQuery } = crudListApi;