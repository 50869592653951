import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Backend } from '../services/api/backend';
import { deserialiseData } from '../components/crud/Base';
import { AssetKey } from '../../declarations/cdn_bucket/cdn_bucket.did.t';

// Define your API service
export const cdnApi = createApi({
  reducerPath: 'cdnApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }), // Adjust the baseUrl according to your setup
  endpoints: (builder) => ({
    getFile: builder.query<any, { fId: any, cid: string }>({
      queryFn: async ({ fId, cid }) => {
        try {
          const bucket = await Backend.loadActorByName('cdn_bucket', cid);
          // Replace with actual method call to get file info
          // const result = await bucket.getFileInfo(fId);
          // const data = deserialiseData(result);
          // Temporary placeholder for fetched data
          const data = {}; // Replace with actual data
          return { data };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
    getAllFiles: builder.query<AssetKey[], { cid: string }>({
      queryFn: async ({ cid }) => {
        try {
          const bucket  = await Backend.loadActorByName('cdn_bucket', cid);
          const result = await bucket.list([]);
          // console.log(result);
          const ds = deserialiseData(result);
          // console.log(ds);
          return { data: ds };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

export const { useGetFileQuery, useGetAllFilesQuery } = cdnApi;