import { memo, useEffect, useRef, useState } from "react";
import { Field } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import {
  CONTEXT_UPDATE,
  emptyOrNull,
  fieldCardinalityOpt,
  fieldName,
  getStateNameExtra,
  wrapNullable,
} from "../crud/Base";
import {
  FormElementState,
  formState,
  setFormValue,
} from "../../reducers/formSlice";
import validateField from "../../services/helpers/validation";
import { validateMsgField } from "../fragments/field-helper";
import { Nullable } from "./nullable";
import { debounce } from "lodash";

export interface InputProps {
  field: Field;
  type: string;
  width: number;
  extras: string;
  data: any;
}

const InputFieldComponent = (props: InputProps): JSX.Element => {
  const { field, type, width, extras, data } = props;
  let defaultEmtpy = "";
  const [value, setValue] = useState(defaultEmtpy);
  const dispatch = useAppDispatch();
  const { form, context } = useAppSelector(formState);
  const [nullValue, setNullValue] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const wrapNumber = (value: any) => {
    if (type !== "number") {
      return value;
    }
    return Number(value);
  };

  const defaultValue = wrapNumber(defaultEmtpy);

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find(
      (f: FormElementState) => f.key === getStateNameExtra(field, extras)
    );
    if (stateValue) {
      const newValue = checked ? defaultValue : null;
      const errs = validateField(field, newValue, !checked);
      setErrors(errs);
      dispatch(setFormValue({ ...stateValue, value: newValue, error: errs }));
      setValue(newValue);
    }
  };

  useEffect(() => {
    // console.log('useEffect');
    const nullable = fieldCardinalityOpt(field);
    let errs = validateField(field, defaultValue);
    if (nullable) {
      setNullValue(false);
      errs = [];
    }

    // console.log(field);
    // console.log(errs);
    const initialObj: FormElementState = {
      key: getStateNameExtra(field, extras),
      value: nullable ? null : defaultValue,
      error: errs,
      extras: getStateNameExtra(field, extras),
    };
    dispatch(setFormValue(initialObj));
    setValue(defaultValue);
    setErrors(errs);
    if (context === CONTEXT_UPDATE && data !== undefined) {
      // console.log(data);
      // console.log(getStateNameExtra(field, extras));
      const updateValue = data.value;
      const valueOrDefault = 
        fieldCardinalityOpt(field) && updateValue === null ? null : updateValue;
        // console.log(valueOrDefault);
      let errs = validateField(field, valueOrDefault);
      // console.log(errs);
      // console.log(valueOrDefault);
      if (!emptyOrNull(updateValue)) {
        setNullValue(true);
      }
      setErrors(errs);
      setValue(valueOrDefault);
      dispatch(
        setFormValue({
          key: getStateNameExtra(field, extras),
          value: valueOrDefault,
          error: errs,
          extras: getStateNameExtra(field, extras),
        })
      );
    }
  }, []);


  const processChange = useRef(debounce((nextValue: string) => {
    const errs = validateField(field, nextValue);  // Validation logic
    dispatch(
      setFormValue({
        key: getStateNameExtra(field, extras),
        value: wrapNumber(nextValue),
        error: errs,
        extras: getStateNameExtra(field, extras),
      })
    );
    setErrors(errs);
  }, 300)).current;

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    setValue(value);
    processChange(value);
  };

  const placeholder = fieldName(field);
  const rows = 2;
  const cols = 2;
 
  return (
    <div className="row g-1">
      <div className="col-md-1">
        <label className="text-break">
          <small>
            <strong>{fieldName(field)}</strong>
          </small>
        </label>
      </div>
      {fieldCardinalityOpt(field) && (
        <div className="col-md-1 d-flex justify-content-center">
          <Nullable field={field} checked={nullValue} onChange={nullableClick} />
          </div>
      )}
      {nullValue === true && (
        <div className={`col-md-${width}`}>
          <div className="form-group">
            {type === "textarea" ? (
              <textarea
                className={`form-control ${
                  errors.length === 0 ? "is-valid" : "is-invalid"
                }`}
                value={value}
                rows={rows}
                cols={cols}
                onChange={handleChange}
                placeholder={placeholder}
              />
            ) : (
              <input
                className={`form-control ${
                  errors.length === 0 ? "is-valid" : "is-invalid"
                }`}
                type={type}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
              />
            )}
            {errors.length > 0 && (
              <div className="invalid-feedback">
                {errors.map((err, i) => (
                  <span key={i}>
                    <strong> - {err}</strong>{" "}
                  </span>
                ))}
              </div>
            )}
            <br />
          </div>
        </div>
      )}
    </div>
  );
};

InputFieldComponent.whyDidYouRender = true;
export const InputField = memo(InputFieldComponent);

export interface SwitchProps {
  field: Field;
  width: number;
  extras: string;
  data: any;
}

const InputSwitchComponent = (props: SwitchProps): JSX.Element => {
  const { field, width, extras, data } = props;
  const defaultValue = false;
  const [value, setValue] = useState(defaultValue);
  // const [fetched, setFetched] = useState(false);
  const dispatch = useAppDispatch();
  const { form, context } = useAppSelector(formState);

  const [nullValue, setNullValue] = useState(false);

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find(
      (f: FormElementState) => f.key === getStateNameExtra(field, extras)
    );
    if (stateValue) {
      dispatch(
        setFormValue({ ...stateValue, value: checked ? null : defaultValue })
      );
    }
  };

  useEffect(() => {
    const nullable = fieldCardinalityOpt(field);
    setNullValue(!nullable);
    const initialObj: FormElementState = {
      key: getStateNameExtra(field, extras),
      value: nullable ? null : defaultValue,
      extras: getStateNameExtra(field, extras),
      error: [],
    };
    dispatch(setFormValue(initialObj));
    // console.log(getStatus);
    if (context === CONTEXT_UPDATE && data !== undefined) {
      // console.log(data);
      const stateName = getStateNameExtra(field, extras);
      if (data.key === stateName) {
        let updateValue = data.value;
        dispatch(
          setFormValue({
            key: getStateNameExtra(field, extras),
            value: updateValue,
            error: [],
            extras: getStateNameExtra(field, extras),
          })
        );
        setValue(updateValue);
      }
    }
  }, []);

  const onClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    setValue(e.target.checked);
    dispatch(
      setFormValue({
        key: getStateNameExtra(field, extras),
        value: e.target.checked,
        extras: getStateNameExtra(field, extras),
        error: [],
      })
    );
  };

  return (
    <div className="row g-0">
      <div className="col-md-1">
        <label className="text-break">
          <small>
            <strong>{fieldName(field)}</strong>
          </small>
        </label>
      </div>
      {fieldCardinalityOpt(field) && (
        <div className="col-md-1">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={nullValue}
              onClick={(e) => nullableClick(e)}
            />
            <label className="form-check-label">nullable</label>
          </div>
        </div>
      )}
      {nullValue === true && (
        <div className={`col-md-${width}`}>
          <div className="form-group">
            <div className="form-check form-switch">
              <input
                className={`form-check-input`}
                type="checkbox"
                defaultChecked={value}
                onChange={(e) => onClick(e)} // Implement this
              />
            </div>
            <br />
          </div>
        </div>
      )}
    </div>
  );
}

export const InputSwitch = memo(InputSwitchComponent);

export interface SimpleInputField {
  onChange: (val: any) => void;
  defaultValue?: any;
  type: string;
  name: string;
}

const SimpleInputFieldComponent = (props: SimpleInputField): JSX.Element => {
  const { type, onChange, defaultValue, name } = props;
  const [value, setValue] = useState<number | string>();
  // console.log(type);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    let value: string | number = e.currentTarget.value;
    if (type === "number") {
      value = Number(value);
    }
    setValue(value);
    onChange(value);
  };
  return (
    <div className="form-group">
      <input
        className="form-control"
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={name}
      />
    </div>
  );
};

export const SimpleInputField = memo(SimpleInputFieldComponent);