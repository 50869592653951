import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Entity } from '../../components/crud/Schema';
import { entityUniqueIdentifier, serviceName } from '../../components/crud/Base';
import { Backend } from '../../services/api/backend';
import { DeleteRequest } from '../../../declarations/game_config/game_config.did.t';


export interface DeleteArgs {
  e: Entity;
  cid: string;
  keys: string[];
};

export const crudDeleteApi = createApi({
  reducerPath: 'crudDeleteApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    deleteById: builder.mutation<any, DeleteArgs>({
      queryFn: async (args) => {
        try {
          
          const loadActor: any = await Backend.loadActorByName(serviceName(args.e) as keyof Backend.ActorTypes, args.cid);
          const obj: DeleteRequest = {
            entity: entityUniqueIdentifier(args.e),
            key: args.keys,
          };
          const result = await loadActor[`crud_delete`](obj);
          
          if (result.Err) {
            return { error: { status: 'CUSTOM_ERROR', error: result.Err }};
          }

          return { data: result.Ok };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

export const { useDeleteByIdMutation } = crudDeleteApi;