import { createContext, lazy, useContext } from "react";
import { useEffect } from "react";
import CollapsibleMenu from "./SideBar";
import { RECORDS_PATH } from "../../reducers/schemaSlice";
import { useAppDispatch } from "../../services/hooks";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Page404 from "../pages/Page404";
import Create from "../crud/Create";
import {
  CanisterState,
  useCdnCanistersQuery,
  useMainCanistersQuery,
} from "../../reducers/canisterSlice";
import { EnvironmentContext } from "../../..";
import LoadingSpinner from "../ui/spinner";
import { getBucketCanister, serviceName, servicePath } from "../crud/Base";
import { Backend } from "../../services/api/backend";
import { Entity } from "../crud/Schema";

const List = lazy(() => import("../crud/List"));
const Edit = lazy(() => import("../crud/Edit"));
// const Create = lazy(() => import('../models-crud/Create'));
// const Profile = lazy(() => import('../pages/Profile'));
// const Page404 = lazy(() => import('../pages/Page404'));
const Dashboard = lazy(() => import("../pages/Dashboard"));

interface ActiveCanistersContextType {
  cdn: string;
  main: CanisterState[];
}

const ActiveCanistersContext = createContext<ActiveCanistersContextType>({
  cdn: "",
  main: [],
});

export const useActiveCanisters = () => useContext(ActiveCanistersContext);

export const getCanisterIdByName = (
  entity: Entity,
  env: string,
  mainCanisters: CanisterState[],
  name?: string,
) => {
  // console.log(mainCanisters);
  // console.log(name);
  const nameSearch = name !== undefined && name !== '' ? name : servicePath(entity);
  // console.log(servicePath(entity));
  // console.log(nameSearch);
  const canister = mainCanisters.find(
    (c: CanisterState) => c.name === nameSearch
  );
  // console.log(canister);
  let canisterId = canister?.cid;

  if (canisterId === undefined) {
    const envCanisterId = Backend.getCanisterIdByName(serviceName(entity), env);
    if (envCanisterId !== "") {
      return envCanisterId;
    }
  } else {
    return canisterId;
  }

  return "";
};

const AdminLayout = () => {
  const { environment } = useContext(EnvironmentContext);
  const dispatch = useAppDispatch();
  const { data: cdnCanisters = [], isLoading: cdnIsLoading } =
    useCdnCanistersQuery(environment);
  const activeCdn = getBucketCanister(cdnCanisters);

  const { isLoading: mainLoading, data: mainCids } =
    useMainCanistersQuery(environment);
  useEffect(() => {}, []);

  if (mainLoading) {
    return (
      <div id="overlay" className={"display-flex"}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <LoadingSpinner altText="loading" theme="lg" />
        </div>
      </div>
    );
  }

  return (
    <ActiveCanistersContext.Provider
      value={{ cdn: activeCdn, main: mainCids ? mainCids : [] }}
    >
      <div>
        <Navbar />
        <div className="container-fluid">
          <div className="row">
            <CollapsibleMenu />
            <main className="col-md-9 ms-sm-auto col-lg-10">
              {activeCdn === "" && (
                <div className="alert alert-danger" role="alert">
                  "No CDN canister loaded... make sure to clean the local
                  storage."
                </div>
              )}
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* <Route path='/profile' element={<Profile />} /> */}
                <Route
                  path={`${RECORDS_PATH}/:model/create/*`}
                  element={<Create />}
                />
                <Route
                  path={`${RECORDS_PATH}/:model/edit/:modelId`}
                  element={<Edit />}
                />
                <Route path={`${RECORDS_PATH}/:model/*`} element={<List />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </main>
          </div>
        </div>
        <Footer />
      </div>
    </ActiveCanistersContext.Provider>
  );
};
export default AdminLayout;
