import { memo, useContext, useEffect, useState } from "react";
import { AssetKey } from "../../../declarations/cdn_bucket/cdn_bucket.did.t";
import { Field } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { useGetAllFilesQuery } from "../../reducers/cdnSlice";
import { CONTEXT_UPDATE, MAX_CHUNK_SIZE, b64toBlob, encodeBase64, encodeFilename, fieldName, getBucketCanister, getFileExtension, getFileFolder, getStateNameExtra, headersBase64, toNullable } from "../crud/Base";
import { FormElementState, FormState, formState, setFormValue } from "../../reducers/formSlice";
import { useCdnCanistersQuery } from "../../reducers/canisterSlice";
import { EnvironmentContext } from "../../..";
import { ulid } from "ulid";
import { ActorSubclass } from "@dfinity/agent";
import {  _SERVICE } from '../../../declarations/cdn_bucket/cdn_bucket.did.t';
import { Backend } from "../../services/api/backend";

export const ASSET_NAME = 'cdn_id';

export const findAssetData = (files: AssetKey[], id: string) => {
  if (files.length === 0) { return null };
  const file = files.find((file) => { 
      const iid = file.id[0] as any;
      return iid === id 
  });
  return file;
}

export interface FileReaderInfo {
  name: string;
  type: string;
  size: number;
  blob: Blob;
  width: number;
  file: number;
  height: number;
}

interface AssetElement {
  field: Field;
  width: number;
  blobType: boolean;
  extras: string;
  data: any;
};


const AssetElementComponent: React.FC<AssetElement> = (props: AssetElement) => {
  const { environment } = useContext(EnvironmentContext);
  const initialText = 'Drag and drop a file or click here';
  const [fileData, setFileData] = useState(initialText);
  const [errors, setErrors] = useState<string[]>([]);
  const [file, setFile] = useState<FileReaderInfo>({
      name: '',
      type: '',
      size: 0,
      blob: new Blob(),
      width: 0,
      file: 0,
      height: 0
  });
  const [ready, setReady] = useState(false);
  const [valid, setValid] = useState(file.name !== '');
  const { field, blobType, extras, data } = props;
  // const { data: cdnCanisters, isLoading: cdnLoading } = useCdnCanistersQuery(environment);
  // if (cdnLoading || !cdnCanisters) {
  //   return <></>;
  // }
  const dispatch = useAppDispatch();
  // const bucketId = getBucketCanister(cdnCanisters);
  // const { data, error, isLoading } = useGetAllFilesQuery({ cid: bucketId });
  const { form, context } = useAppSelector(formState);
  
  useEffect(() => {
      if (context === CONTEXT_UPDATE && Object.values(data).length > 0) {
        console.log(data);
      //   console.log('trigger update');
      //   console.log(getStateNameExtra(field, extras));
      //   const updateValue: any = Object.values(getData).find((formVal: any) =>  formVal.key === getStateNameExtra(field, extras));
        const getValue = data.value;
      //   // console.log(getValue);
        dispatch(setFormValue({
          key: getStateNameExtra(field, extras),
          value: getValue,
          error: [],
          extras: getStateNameExtra(field, extras)
        }));
        setValid(true);
        setFileData("Asset id is: " + getValue);
      } else {
        setFileData(initialText);
        setReady(true);
        const initialObj: FormElementState = {
          key: getStateNameExtra(field, extras),
          value: '',
          error: [],
          extras: getStateNameExtra(field, extras)
        };
        if (!valid) {
            initialObj['error'] =  ['Field is required!'];
        }
        dispatch(setFormValue(initialObj));
      }
  }, []);

  const preview = async (file: any, blob: Blob) : Promise<Blob> => {
    return new Promise<any>((resolve) => {
      if (file.type.split('/')[0] !== 'image') {
        resolve(undefined);
      }
      const max = 100;
      let image = new Image();
      image.src = URL.createObjectURL(blob);
      image.onload = () => {
        let oc = document.createElement('canvas');
        let octx = oc.getContext('2d');
        if (octx !== null) {
          oc.width = image.width;
          oc.height = image.height;
          octx.drawImage(image, 0, 0);
          if( image.width > image.height) {
            oc.height = (image.height / image.width) * max;
            oc.width = max;
          } else {
            oc.width = (image.width / image.height) * max;
            oc.height = max;
          }
          octx.drawImage(oc, 0, 0, oc.width, oc.height);
          octx.drawImage(image, 0, 0, oc.width, oc.height);
          resolve(b64toBlob(headersBase64(oc.toDataURL()), file.type));
        };
      };
      image.onerror = () => {
        resolve(undefined);
      };
    });
  }

  const handleUpload = async (event: React.FormEvent<HTMLInputElement>) => {
    setReady(false);
    setErrors([]);
    // @ts-ignore
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      const errors: string[]= [];
      if (reader.result === null) {
        errors.push("Invalid format!");
        return;
      }
      // console.log(reader.result);
      // console.log(file.type);
      const blob = b64toBlob(headersBase64(reader.result), file.type);
      const fileIn: FileReaderInfo = {
        name: file.name,
        type: file.type,
        size: file.size,
        blob: blob,
        file: file,
        width: file.width,
        height: file.height
      };
      setFileData(file.name + ' | ' + Math.round(file.size / 1000) + ' kB');

      const stateValue: FormElementState | undefined = form.find((f: FormElementState) => f.key === getStateNameExtra(field, extras) );
      if (file === null || file === undefined || getFileExtension(file.type) === null) {
        errors.push("Invalid format!");
      }
      if (file.size > 10550000) {
        errors.push("File size shouldn't be bigger than 10mb");
      }

      if (errors.length > 0) {
        setErrors(errors);
        if (stateValue) {
          const copy : FormElementState = {
            ...stateValue,
            key: getStateNameExtra(field, extras),
            error: errors,
          };
          console.log(copy);
          dispatch(setFormValue(copy));
          }
        return;
      }
      setFile(fileIn);
      setReady(true);
      if (blobType && stateValue) {
        const copy : FormElementState = {
          ...stateValue,
          key: getStateNameExtra(field, extras),
          value: await encodeBase64(blob) as any,
          error: [],
        };
        dispatch(setFormValue(copy));
        return;
      }

      const id = ulid();
    
      const pre: Blob = await preview(file, blob);
      const fileName = encodeFilename(file.name);
      const fileInfo : AssetKey = {
          id: toNullable(id),
          name: `${fileName}-${id}`,
          created: Number(Date.now()) as any,
          size: file.size,
          folder: getFileFolder(file.type),
          full_path: `${getFileFolder(file.type)}/${fileName}-${id}`,
          preview: toNullable(await encodeBase64(pre) as any),
      };
      const extraFileInfo = { ...fileInfo, type: file.type };
      // console.log(fileInfo);
      if (stateValue) {
        // console.log(stateValue);
        const copy : FormElementState = {
          ...stateValue,
          value: '',
          error: [],
          pre: {
              fileInfo: extraFileInfo,
          },
          post: {
            chunks: await encodeBase64(blob) as any,
          }
        };
        dispatch(setFormValue(copy));
      }
    };
  };

  const classes = (!valid && !ready) ? 'image-upload-wrap border-err' : 'image-upload-wrap';
  return  <div className="row g-1">
  <div className="col-md-1">
    <label className="text-break form-label">
      <small><strong>{fieldName(field)}</strong></small>
    </label>
  </div>
  <div className={`col-md-10 ${classes}`} key={fieldName(field)}>
    <div className="drag-text">
      <input className="file-upload-input" type='file' onChange={handleUpload} />
      <h3>{fileData}</h3>
      <img src='http://100dayscss.com/codepen/upload.svg' className='upload-icon'/>
    </div>
    {errors && errors.map((err: string, index: number) => (
      <div key={index} className="alert alert-danger" role="alert">{err}</div>
    ))}
    <br/>
  </div>
  </div>
};

export const AssetElement = memo(AssetElementComponent);

export interface Asset {
  id: any;
  size?: string;
  cdnData: AssetKey[]
};

const AssetViewerComponent: React.FC<Asset> = ({ id, size, cdnData }: Asset) : JSX.Element => {
  const { environment } = useContext(EnvironmentContext);
  const [loaded, setLoaded] = useState(false);

  const { data: cdnCanisters, isLoading: canistersLoading } = useCdnCanistersQuery(environment);
  if (canistersLoading || !cdnCanisters) {
    return <></>;
  }
  console.log(cdnData);
  const assetData = findAssetData(cdnData, id);
  // // console.log(assetData);
  if (!assetData) {
    return <p className="text-warning">Asset with id {id} not found in the CDN canister!</p>;
  }
  
	// @ts-ignore
	const blobrec = new Blob([new Uint8Array(assetData.preview.split(',').map(x => parseInt(x,10)))] );
	const url = URL.createObjectURL(blobrec);
  console.log(url);
  const sizePx = {
    maxHeight: (size ? size : '' ) + "px"
  };
  return <>
    <img style={sizePx} src={url} />
  </>
};

export const AssetViewer = memo(AssetViewerComponent);

export const processAndUploadChunk = async (
  batch_id: any,
  chunk: Blob,
  bucket: ActorSubclass<_SERVICE>,
) : Promise<any> => {
  return bucket.upload_chunk({batch_id: batch_id, content: new Uint8Array(await chunk.arrayBuffer())});
}

export const postModify = async (form: FormElementState[], cid: string) => {
  console.log(form);
  for (const formData of form) {
    if (formData.post && formData.key === ASSET_NAME) {
      console.log(formData);
      const chunks = [];
      // @ts-ignore
      const batchId = BigInt(formData.batchId);
      console.log(batchId);
      let chunk = 1;
      const blob = b64toBlob(headersBase64(formData.post.chunks), formData.pre.fileInfo.type);
      const bucket  = await Backend.loadActorByName('cdn_bucket', cid);
      console.log(formData);
      for (let byteStart = 0; byteStart < blob.size; byteStart += MAX_CHUNK_SIZE, chunk++ ) {
          const chunkBlob: Blob = blob.slice(byteStart, byteStart + MAX_CHUNK_SIZE, blob.type);
          chunks.push(processAndUploadChunk(batchId, chunkBlob, bucket)
        );
      }
      const chunkIds = await Promise.all(chunks);
      console.log(chunkIds);
      console.log(formData.pre.fileInfo.type);
      console.log(BigInt(batchId));
      console.log(chunkIds.map(({chunk_id}: {chunk_id: bigint}) => chunk_id));
      let res = await bucket.commit_upload({ 
        batch_id: BigInt(batchId),
        chunk_ids: chunkIds.map(({chunk_id}: {chunk_id: bigint}) => chunk_id),
        headers: [['Content-Type', formData.pre.fileInfo.type], ['accept-ranges', 'bytes']]
       });
       console.log(res);
    }
  }
};