import { memo, useEffect, useState } from "react";
import { Field, Newtype } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { FormElementState, deleteByKey, filterAndUpdate, formState, setFormValue } from "../../reducers/formSlice";
import { CONTEXT_UPDATE, constructFieldFromType, emptyOrNull, entityUniqueIdentifier, fieldName, getStateNameExtra } from "../crud/Base";
import FormElement from "./form.element";
import { extractKeys } from "../crud/DataProcessor";

interface ListSetElement {
  field: Field;
  width: number;
  parentRef: Newtype;
  extras: string;
  data: any;
};

const ListSetElement: React.FC<ListSetElement> = (props: ListSetElement): JSX.Element => {
  const [nullValue, setNullValue] = useState(false);
  const [valueMap, setValueMap] = useState(new Map<number, Field>());
  const dispatch = useAppDispatch();
  const { field, width, extras, parentRef, data } = props;
  const { form, context } = useAppSelector(formState);
  // console.log(parentRef);
  const constructedField: Field = constructFieldFromType(parentRef.value.item, 'One', entityUniqueIdentifier(parentRef));

  // console.log(constructedField);

  useEffect(() => {
    if (context === CONTEXT_UPDATE && data.length > 0) {
      const keys = extractKeys(field, data, extras);
      // console.log(keys);
      const newMap = new Map(valueMap);
      for (let i = 0; i < keys.length; i++) {
        newMap.set(i, field);
      }
      setValueMap(newMap);
    } else { 
      const initialObj: FormElementState = {
        key: getStateNameExtra(field, extras),
        value: [],
        error: [],
        extras: getStateNameExtra(field, extras)
      };
      dispatch(setFormValue(initialObj));
    }
  }, []);
  // console.log(getEntity(parentRef.key) as Entity);
  const add = (e: React.MouseEvent): void => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    const newKey = valueMap.size ? Math.max(...Array.from(valueMap.keys())) + 1 : 0; // compute the new key
    newMap.set(newKey, field);
    dispatch(deleteByKey(getStateNameExtra(field, extras)));
    setValueMap(newMap);
  };

  const remove = (e: React.MouseEvent, i: number, f: any) => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    newMap.delete(i);
    setValueMap(newMap);
    dispatch(filterAndUpdate(f));
    if (Array.from(newMap.values()).filter((e: any) => !emptyOrNull(e)).length === 0) {
      dispatch(setFormValue({
        key: getStateNameExtra(field, extras),
        value: [],
        extras: getStateNameExtra(field, extras),
        error: []
      }));
    }
  }
  return <>
    <div className="col-md-1">
      <label className="text-break">
        <small><strong>{fieldName(field)}</strong></small>
      </label>
    </div>
    <div className={`col-md-${width}`}>
      {Array.from(valueMap.entries()).map(([key, childField]) => {
        const fieldExtra = `${fieldName(field)}-array_key_${key}-`;
        return (
          <div key={key} className='row container border rounded mb-1 mt-1 g-1'>
            <FormElement
              field={constructedField}
              width={width}
              extras={extras + fieldExtra}
              data={data}
            />
            {valueMap.size > 0 &&
              <button onClick={(e: any) => remove(e, key, extras + fieldExtra)} className="btn btn-outline-danger close-button">x</button>
            }
          </div>
        );
      })}
      <div className="col-md-2">
        <div>
          <br />
          <button onClick={(e) => add(e)} className="btn btn-sm btn-primary">
            Add new: {fieldName(field)}
          </button>
        </div>
      </div>
    </div>
  </>

};

export default memo(ListSetElement);