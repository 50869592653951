import * as React from "react";
import { loadState } from "../../reducers/schemaSlice";
import { Field } from "../crud/Schema";

export interface ListViewer {
  field: Field;
  data: any;
}

export function ListViewer(props: ListViewer): JSX.Element {
  const { data, field } = props;
  const schema = loadState();
  const elems: any = [];
return (
  <div className="container">
  <ul id="myUL">
    <TreeItem item={data} />
  </ul>
</div>
);
}


const TreeItem = ({ item }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const renderContent = (data: any) => {
    console.log(data);
    if (Array.isArray(data)) {
      return (
        <ol>
          {data.map((subItem, index) => (
            typeof subItem === 'object' && subItem !== null ? (
              <TreeItem key={index} item={subItem} idx={index} />
            ) : (
              <li key={index}>{subItem}</li>
            )
          ))}
        </ol>
      );
    } 
    else if (typeof data === 'object' && data !== null) {
      // console.log(data);
      return (
        <ul className={isOpen ? "nested active" : "nested"}>
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              <strong>{key}: </strong>
              {(typeof value === 'object' && value !== null) ? <TreeItem item={value} key={key} /> :safeToString(value)}
            </li>
          ))}
        </ul>
      );
    } 
    else {
      return <li>{data}</li>;
    }
  };

  return (
    <li>
      <span className={`caret${isOpen ? " caret-down" : ""}`} onClick={toggle}></span>
      {isOpen && renderContent(item)}
    </li>
  );
};

const safeToString = (x: any): any => {
  if (x === null || x === undefined || x === '') return <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;
  if (typeof x === 'object') return JSON.stringify(x);
  return x.toString();
};
