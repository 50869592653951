import { emptyOrNull, fieldCardinalityMany, fieldCardinalityOpt } from "../../components/crud/Base";
import { Field } from "../../components/crud/Schema";


const validateField = (field: Field, value: any, skip?: boolean): string[] => {
  let required = true;
  if (fieldCardinalityMany(field)) {
    required = false;
  }
  const validationMsg: string[] = [];
  if (skip && skip === true) {
    return validationMsg;
  }

  if (emptyOrNull(value)) {
    required = false;
  }
  if (required) {
    const req = validateRule('required', required, value);
    if (typeof req === 'string') {
        validationMsg.push(req);
    }
  };
 
  return validationMsg;
};
const empty = (value: any) => (value === undefined || value === null || value === '');
const validateRule = (rule: any, ruleValue: any, elemValue: any): string | boolean => {
  switch (rule) {
      case 'required':
          return required(ruleValue, elemValue);
      case 'minLength':
          return minLength(ruleValue, elemValue);
      case 'maxLength':
          return maxLength(ruleValue, elemValue);
      case 'min':
          return min(ruleValue, elemValue);
      case 'max':
          return max(ruleValue, elemValue);
      case 'customRegex':
          return customRegex(ruleValue, elemValue);
      default:
          return true;
  }
};

const required = (req: boolean, value: any): string | boolean => {
  return ((value === undefined || value === null || value === '')  && req) ? 'Field is required!' : true;
};
const minLength = (minLength: number, value: string): string | boolean => {
  return (typeof  value === 'string' && value.length < minLength) ? `Length has to be at least ${minLength} chars!` : true;
};
const maxLength = (maxLength: number, value: string): string | boolean => {
  return (typeof  value === 'string' && value.length > maxLength) ? `Length has to be at most ${maxLength} chars!` : true;
};
const min = (min: number, value: number): string | boolean => {
  return (typeof  value === 'number' && value < min) ? `Value has to be at least ${minLength}!` : true;
};
const max = (max: number, value: number): string | boolean => {
  return (typeof  value === 'number' && value > max) ? `Value has to be at most ${maxLength}!` : true;
};
const customRegex = (regex: string, value: string): string | boolean => {
  const customRegex = new RegExp(regex);
  return (customRegex.test(value)) ? `Field not passing regex validation ${regex}!` : true;
};

export default validateField;
