import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Entity } from '../../components/crud/Schema';
import { decodeData, encodeCbor, entityUniqueIdentifier, serviceName } from '../../components/crud/Base';
import { Backend } from '../../services/api/backend';
import { SaveRequest } from '../../../declarations/game_config/game_config.did.t';

export interface UpdateArgs {
  e: Entity;
  cid: string;
  objectData: any;
  keys: string[];
};

export const crudEditApi = createApi({
  reducerPath: 'crudEditApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  tagTypes: ['List'],
  endpoints: (builder) => ({
    update: builder.mutation<any, UpdateArgs>({
      queryFn: async (args) => {
        try {
          const loadActor: any = await Backend.loadActorByName(serviceName(args.e) as keyof Backend.ActorTypes, args.cid);
          const deserialised = JSON.parse(args.objectData, (key, value) => {
            if (typeof value === "string" && /^\d+n$/.test(value)) {
              return BigInt(value.substr(0, value.length - 1));
            }
            return value;
          });
          console.log(deserialised);
          const obj: SaveRequest = {
            data: encodeCbor(deserialised),
            action: { Update: null },
            entity: entityUniqueIdentifier(args.e),
          };
          const result = await loadActor[`crud_save`](obj);
          console.log(result);
          if (result.Err) {
            const errArr = result.Err.errors && Array.isArray(result.Err.errors) && result.Err.errors.length > 0 ? result.Err.errors : [JSON.stringify(result.Err)]  ;
            console.log(errArr);
            return { error: { status: 'CUSTOM_ERROR', error: JSON.stringify(errArr) }};
          }

          return { data: decodeData([result.Ok.Update.row]) };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
      invalidatesTags: ['List'],
    }),

  }),
});

// Export the hook for the update mutation
export const { useUpdateMutation } = crudEditApi;