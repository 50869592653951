import { memo, useEffect } from "react";
import { loadState } from "../../reducers/schemaSlice";
import { CONTEXT_CREATE, CONTEXT_UPDATE, bigNumberStringToBigInt, fieldName, getPrimitive, getStateNameExtra } from "../crud/Base";
import { Field, PrimitiveType } from "../crud/Schema";
import { FormElementState, formState, setFormValue } from "../../reducers/formSlice";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import moment from "moment";


interface HiddenProps{
  field: Field;
  extras: string;
  data: any;
};
const HiddenElement = (props: HiddenProps): JSX.Element => {
  const { field, data, extras } = props;
  const dispatch = useAppDispatch();
  const { context } = useAppSelector(formState);
  let value: any = 0;
  // console.log(field);
  // console.log(fieldName(field));
  // console.log(extras);
  if ((fieldName(field) === 'created' || fieldName(field) === 'modified' || (fieldName(field) === 'id' && context === CONTEXT_CREATE) )) {
    return <></>;
  }
  if (fieldName(field) === 'id') {
    value =  '0';
  }
  
  const schema = loadState();
  const primitiveType = (getPrimitive(schema, field) as PrimitiveType);
  let todo = false;
  if (primitiveType && primitiveType.def && primitiveType.def.ident === 'Todo') {
    todo = true;
    value = false;
  }
  useEffect(() => {
    const initialObj : FormElementState = {
      key: getStateNameExtra(field, extras),
      value: value,
      error: [],
      extras: getStateNameExtra(field, extras)
    };
    dispatch(setFormValue(initialObj));
    if (context === CONTEXT_UPDATE && data !== undefined) {
    //   const stateName = getStateNameExtra(field, extras);
    //   const hiddenValue = Object.values(getData).find((formVal: any) => { return  formVal.key === stateName });
    //   const getValue = hiddenValue.value;
    //   // console.log(getValue);
      // console.log(data);
      dispatch(setFormValue({
        key: fieldName(field),
        value: data.value,
        error: [],
        extras: getStateNameExtra(field, extras)
      }));
    //   setFetched(true);
    }
  }, []);
  if (todo) {
    return<label className="text-break">
    <small className="alert alert-danger p-1">
      <strong >{fieldName(field) + " : " }</strong>
      TODO</small>
    </label>;
  }
  if (context === CONTEXT_UPDATE && data !== undefined) {
    if (fieldName(field) === '_created' || fieldName(field) === '_modified') {
      if (data.value === 0) {
        return <div></div>;
      }
      return <div>{fieldName(field)} : {moment(Number(bigNumberStringToBigInt(data.value)) / 1000000).format('YYYY-MM-DD hh:mm:ss')}</div>;
    }
    
    return <label className="text-break">
      <small><strong>{fieldName(field) + " : " + data.value }</strong></small>
      </label>;
  }

  return (
      <div className={'d-none'}></div>
  );
};

export default memo(HiddenElement);