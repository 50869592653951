import { ActorSubclass } from '@dfinity/agent';
import { AuthClient, LocalStorage } from "@dfinity/auth-client";

import { idlFactory as CdnBucketIDL } from "../../../declarations/cdn_bucket/cdn_bucket.did";
import { _SERVICE as CdnBucket } from "../../../declarations/cdn_bucket/cdn_bucket.did.t";

import { idlFactory as CdnContainerIDL } from "../../../declarations/cdn_container/cdn_container.did";
import { _SERVICE as CdnContainer } from "../../../declarations/cdn_container/cdn_container.did.t";

import { idlFactory as UserIDL } from "../../../declarations/user/user.did";
import { _SERVICE as User } from "../../../declarations/user/user.did.t";

import { idlFactory as GameConfigIDL } from "../../../declarations/game_config/game_config.did";
import { _SERVICE as GameConfig } from "../../../declarations/game_config/game_config.did.t";

import { idlFactory as TestIDL } from "../../../declarations/test/test.did";

import { _SERVICE as Test } from "../../../declarations/test/test.did.t";

import { idlFactory as GameStateIDL } from "../../../declarations/game_state/game_state.did";
import { _SERVICE as GameState } from "../../../declarations/game_state/game_state.did.t";

import { idlFactory as WorldIDL } from "../../../declarations/world/world.did";
import { _SERVICE as World } from "../../../declarations/world/world.did.t";

import { idlFactory as WorldBuilderIDL } from "../../../declarations/world_builder/world_builder.did";
import { _SERVICE as WorldBuilder } from "../../../declarations/world_builder/world_builder.did.t";

import { idlFactory as RootIDL } from "../../../declarations/root/root.did";
import { _SERVICE as Root } from "../../../declarations/root/root.did.t";

import { idlFactory as GameIDL } from "../../../declarations/game/game.did";
import { _SERVICE as Game } from "../../../declarations/game/game.did.t";


import { LoadRequest } from "../../../declarations/game_config/game_config.did.t";
export type Query = LoadRequest;

import { createActor } from './actor';
export const createAuthClient = (): Promise<AuthClient> =>
  AuthClient.create({
    // storage: new LocalStorage(),
    idleOptions: {
      // disableIdle: true,
      idleTimeout: 1000 * 60 * 30,
      disableDefaultIdleCallback: true
    }
});

export namespace Backend {

  var authClient: AuthClient;

  export async function setAuthClient(ac: AuthClient) {
    authClient = ac;
  }

  export function getAuthClient() {
    return authClient;
  }

  function ensureAuthClientInitialized(): Promise<void> {
    if (!authClient) {
      return createAuthClient().then(client => {
        authClient = client;
      });
    }
    return Promise.resolve();
  }

  export function createGenericActor(IDL: any, canisterId: string): Promise<ActorSubclass<any>> {
    return ensureAuthClientInitialized().then(() => {
      return createActor({
        IDL,
        canisterId,
        agentOptions: {
          identity: authClient.getIdentity(),
          verifyQuerySignatures: false,
          host: process.env.NODE_ENV !== "production" ? 'http://127.0.0.1:4943' : 'https://icp-api.io'
        },
      });
    });
  }

  export type ActorTypes = {
    Root: Root;
    World: World;
    WorldBuilder: WorldBuilder;
    GameConfig: GameConfig;
    GameState: GameState;
    Test: Test;
    User: User;
    cdn_container: CdnContainer;
    cdn_bucket: CdnBucket;
    Game: Game;
  };

  const canisterConfig: any = {
    Root: { IDL: RootIDL, id: process.env.ROOT_CANISTER_ID },
    GameConfig: { IDL: GameConfigIDL, id: undefined },
    World: { IDL: WorldIDL, id: undefined },
    GameState: { IDL: GameStateIDL, id: undefined },
    WorldBuilder: { IDL: WorldBuilderIDL, id: undefined },
    Test: { IDL: TestIDL, id: process.env.TEST_CANISTER_ID },
    User: { IDL: UserIDL, id: undefined },
    cdn_container: { IDL: CdnContainerIDL, id: process.env.CDN_CONTAINER_CANISTER_ID },
    cdn_bucket: { IDL: CdnBucketIDL, id: undefined },
    Game: { IDL: GameIDL, id: undefined },
  };

  export function getCanisterIdByName(name: string, enviroment: string): string {
    // console.log(process.env);
    // console.log(name);
    // console.log(enviroment);
    const config = canisterConfig[name];
    // console.log(config);
    if (!config) {
      throw new Error(`Unknown canister name: ${name}`);
    }
    if (config.id === undefined) {
      return config.id;
    }
    return config.id[enviroment];
  }

  export async function loadActorByName<K extends keyof ActorTypes>(name: K, canisterId: string): Promise<ActorSubclass<ActorTypes[K]>> {
    const config = canisterConfig[name];
    if (!config) {
      throw new Error(`Actor not found for name: ${name}`);
    }
    const actor = await createGenericActor(config.IDL, canisterId);
    return actor as ActorSubclass<ActorTypes[K]>; // cast here to ensure type safety
  }
}
